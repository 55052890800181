class GlObject extends PIXI.Container {  
  init(index, el, fxscene, getPosScroll) {
    this.idIndex = index;
    this.el = el;

    this.fxscene = fxscene;
    this.visibleScrollFix = true;
    this.getPosScroll = getPosScroll;
    
    this.resize();
  }

  setBounds(withoutup, withoutus) {
    this.rect = this.el.getBoundingClientRect();
    
    var fixscro = this.getPosScroll();

    this.bounds = {
      left: this.rect.left,
      top: this.rect.top + fixscro.y,
      width: this.rect.width,
      height: this.rect.height
    };

    if(withoutus)
      this.updateSize();

    if(withoutup)
      this.updatePosition();
  }
  
  resize() {
    if (!this.visible) return;
    this.setBounds(true, true);

  }
  

  updateSize() {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    this.camUnit = {width: vw, height: vh};

    // const x = this.bounds.width / window.innerWidth;
    // const y = this.bounds.height / window.innerHeight;

    // if (!x || !y) return;

    // this.scale.x = this.camUnit.width * x;
    // this.scale.y = this.camUnit.height * y;
  }
  
  updateY(y = 0) {
    const { top, height } = this.bounds;
    //console.log(top, y, height, window.innerHeight);
    this.position.y = (top - y);//(this.camUnit.height / 2) - (this.scale.y / 2);
    //this.position.y -= ((top - y); // / window.innerHeight) * this.camUnit.height;

    //this.progress = gsap.utils.clamp(0, 1, 1 - (-y + top + height) / (window.innerHeight + height));
   
  }  

  updateX(x = 0) {
    const { left } = this.bounds;

    this.position.x = left - x;//-(this.camUnit.width / 2) + (this.scale.x / 2);
    // this.position.x += ((left + x) / window.innerWidth) * this.camUnit.width;
  }  
  
  resizeFixTexture(){}

  updatePosition(y) {

    var ischa = true;
    
    if(this.targetViewScroll){
      //console.log('hey! ', this.targetViewScroll.dataset.scrollSectionInview );
      if(this.targetViewScroll.dataset.scrollSectionInview != undefined){
        if(!this.visibleScrollFix){
          ischa = true;
        }
        
        this.visibleScrollFix = true;
      }else{
        this.visibleScrollFix = false;
      }
    }

    if(!this.visibleScrollFix && y){
      this.visible = false;
    }else if(this.visibleScrollFix){
      this.visible = true;

      this.setBounds(false, true);

      if(ischa){
        this.resizeFixTexture();
      }
    }

    this.resizeFixTexture();
    this.updateY(y);
    this.updateX(0);

    if(window.innerHeight < this.position.y && this.noalfa !== true){
      this.alpha = 0;
    }else{
      this.alpha = 1;
    }
  }

  setTargetView(strtarget, _target){
    this.targetViewScroll = document.querySelector(strtarget+' #'+_target);
  }

  //scrollSectionInview
  isActiveScreen(){
    var fixscro = this.getPosScroll();
    var viewportHeight = window.innerHeight;
    var posy = this.bounds.top - fixscro.y;

    return posy < viewportHeight && posy > -(this.bounds.height)
  }

  updateTime(time) {

    if(this.targetViewScroll && this.isActiveScreen()){
      //this.material.uniforms.uTime.value = time;
    }
  }

}

export default GlObject;