import BtnTropical from "../tropical/utils/BtnTropical";
import { getUrlAssets, replaceAll } from "../tropical/utils/Utils";

class Coctail {

    constructor(_pixijsManager, _htmlToGl){

        var _this = this;
        this.pixijsManager = _pixijsManager;
        this.htmlToGl = _htmlToGl;

        const strfindbtn = '.cocteleriacont .itemcocteleria';

        this.bloqueobtns = false;

        var btns;
        
        if(dvtp == 0){

            btns = new BtnTropical(function(){
                return this.bloqueobtns;
            }.bind(this),{
                click:function(e){
                    const element = e.target;
                    const id = element.getAttribute('data-plane-id');
                    const idcoctail = element.getAttribute('data-coctail-id');
                    const urlvid = element.getAttribute('data-url-vid');
                    const tbcolor = element.getAttribute('data-tb-color');

                    const tbdatatitle = element.getAttribute('data-dtitle')
                    const tbdataingredientes = element.getAttribute('data-dingredientes')
                    const tbdatapreparacion = element.getAttribute('data-dpreparacion')
                    const tbdatalinkcomprar = element.getAttribute('data-dlinkcompra')
                    const tbsdatabg = element.getAttribute('data-dbg')
                    const tbsdatasello = element.getAttribute('data-sello')
                    
                    var objte = { 
                        title: tbdatatitle, 
                        ingredientes: tbdataingredientes, 
                        preparacion: tbdatapreparacion, 
                        linkcomprar: tbdatalinkcomprar, 
                        bg: tbsdatabg, 
                        sello: tbsdatasello
                    }

                    _this.goOneProd(element, idcoctail, urlvid, tbcolor, objte);
                },
                over:function(e){
                    if(dvtp == 0){

                        const ele = e.target;
                        const idele = ele.getAttribute('data-plane-id');
                        /* var planetemp = _this.htmlToGl.getPlaneTB(idele);
                        planetemp.mouseEnter(); */
                    }
                },
                out:function(e){
                    if(dvtp == 0){

                        const ele = e.target;
                        const idele = ele.getAttribute('data-plane-id');
                        /* var planetemp = _this.htmlToGl.getPlaneTB(idele);
                        planetemp.mouseLeave(); */
                    }
                }
            });

            this.setCarrusel();
            
        } else {

            btns = new BtnTropical(function(){
                return this.bloqueobtns;
            }.bind(this),{
                click:function(e){
                    const element = e.target;
                    const id = element.getAttribute('data-plane-id');
                    const idcoctail = element.getAttribute('data-coctail-id');
                    const urlvid = element.getAttribute('data-url-vid');
                    const tbcolor = element.getAttribute('data-tb-color');
                    
                    const tbdatatitle = element.getAttribute('data-dtitle')
                    const tbdataingredientes = element.getAttribute('data-dingredientes')
                    const tbdatapreparacion = element.getAttribute('data-dpreparacion')
                    const tbdatalinkcomprar = element.getAttribute('data-dlinkcompra')
                    const tbsdatabg = element.getAttribute('data-dbg')
                    const tbsdatasello = element.getAttribute('data-sello')
                    
                    var objte = { 
                        title: tbdatatitle, 
                        ingredientes: tbdataingredientes, 
                        preparacion: tbdatapreparacion, 
                        linkcomprar: tbdatalinkcomprar, 
                        bg: tbsdatabg, 
                        sello: tbsdatasello
                    }

                    _this.goOneProd(element, idcoctail, urlvid, tbcolor, objte);
                }
            });
        }

        this.confignextprev();

        btns.addBtn(strfindbtn);

        //this.yeye = getCoctails();
        this.yeye = document.querySelectorAll('#sec_cocteleria .cocteleriacont .itemcocteleria');

        window.addEventListener('resize', this.handleResize.bind(this), false);
    }

    confignextprev(){

        var _this = this;

        var btns = new BtnTropical(function(){
            return false;
        }.bind(this),{
            click:function(e){
                const ele = e.target;
                const dataside = ele.getAttribute('data-side');

                var _idcoctail = _this.idselecto+1;
                var nle = Number(_this.yeye.length);

                if(dataside == 'left'){
                    _idcoctail = _this.idselecto-1;

                    if( _idcoctail < 0 ){
                        _idcoctail = nle-1;
                    }

                }else{

                    if( _idcoctail >= (nle) ){
                        _idcoctail = 0;
                    }
                }

                const eleidprod = _this.getidplane(_idcoctail);

                const id = eleidprod.getAttribute('data-plane-id');
                const idcoctail = eleidprod.getAttribute('data-coctail-id');
                const urlvid = eleidprod.getAttribute('data-url-vid');
                const tbcolor = eleidprod.getAttribute('data-tb-color');

                const tbdatatitle = eleidprod.getAttribute('data-dtitle')
                const tbdataingredientes = eleidprod.getAttribute('data-dingredientes')
                const tbdatapreparacion = eleidprod.getAttribute('data-dpreparacion')
                const tbdatalinkcomprar = eleidprod.getAttribute('data-dlinkcompra')
                const tbsdatabg = eleidprod.getAttribute('data-dbg')
                const tbsdatasello = eleidprod.getAttribute('data-sello')
                
                var objte = { 
                    title: tbdatatitle, 
                    ingredientes: tbdataingredientes, 
                    preparacion: tbdatapreparacion, 
                    linkcomprar: tbdatalinkcomprar, 
                    bg: tbsdatabg, 
                    sello: tbsdatasello
                }

                _this.goNP(ele, idcoctail, urlvid, tbcolor, objte);
            }
        });

        btns.addBtn('.fixmodalcoctail .btnsnp .btn');
    }

    getidplane(_idcoctail){
        const arrchild = document.querySelectorAll('#sec_cocteleria .cocteleriacont .itemcocteleria');

        //ele.getAttribute('data-plane-id');
        var idreturn = null;
        for (let i = 0; i < arrchild.length; i++) {
            const element = arrchild[i];
            var idcoctail = element.getAttribute('data-coctail-id');

            if(_idcoctail == idcoctail){
                idreturn = element;
                break;
            }
        }

        return idreturn;
    }

    goNP(id, idcoctail, urlvid, tbcolor, _data){
        var _this = this;

        var modalcoctail = document.querySelector('.modal_coctail');
        var continfo =  modalcoctail.querySelector('.continfo .continfofix');
        var cninfo = continfo.childNodes;
        var lastdelay = 0;

        for (let i = 0; i < cninfo.length; i++) {
            const element = cninfo[i];
            
            var ndela = .4+(.04*i);
            lastdelay = ndela;

            gsap.to(element, {
                delay: ndela,
                css: {
                    opacity: 0
                },
            });
        }

        lastdelay = lastdelay+.1;

        gsap.to(this.bgcover, {
            // duration: 1,
            delay: lastdelay,
            alpha: 0,
            onComplete:function(){
                _this.goOneProd(id, idcoctail, urlvid, tbcolor, _data);
            }    
        });

    }

    changePageCarrusel(noresize, pagenew){
        const vw = window.innerWidth;
        const vh = window.innerHeight;

        var conctfix = document.querySelectorAll('#sec_cocteleria .cocteleriacont .ccontfix');
        var contcoctailcarrusel = document.querySelectorAll('#sec_cocteleria .cocteleriacont .itemcocteleria');
        var conttbw = (vw/3);

        var maxiw = conttbw*contcoctailcarrusel.length;
        var maxipos = -(maxiw-vw);
        var newipos = -(pagenew*vw);

        if(pagenew < 0){
            pagenew = 0;
            newipos = 0;
        }

        if(maxipos > newipos){
            newipos = maxipos;
        }else{
            this.pagecurrent = pagenew;
        }

        gsap.to(conctfix, {css:{'transform': 'translate3d('+newipos+'px,0,0)'} });
    }

    positbs(){
        console.log('ye')
        const vw = window.innerWidth;
        var contcoctailcarrusel = document.querySelectorAll('#sec_cocteleria .cocteleriacont .itemcocteleria');
        var conttbw = (vw/3);

        for (let i = 0; i < contcoctailcarrusel.length; i++) {
            const element = contcoctailcarrusel[i];
            var newipos = i*conttbw;

            if(vw <= 950){
                element.setAttribute('style', '');
            }else{
                gsap.to(element, {css:{'left': newipos+'px', 'width': conttbw+'px'} });
            }

            
        }

    }

    resizeCarrusel(){
        this.positbs();
        this.changePageCarrusel(false, this.pagecurrent);
    }

    setCarrusel(){
        var contcoctailcarrusel = document.querySelectorAll('#sec_cocteleria .cocteleriacont .itemcocteleria');
        var _this = this;
        this.pagecurrent = 0;

        var btns = new BtnTropical(function(){
            return this.bloqueobtns;
        }.bind(this),{
            click:function(e){
                const element = e.target;
                const side = element.getAttribute('data-side');

                var newpage = _this.pagecurrent+1;

                if(side == 'left'){
                    newpage = _this.pagecurrent-1;
                }

                _this.changePageCarrusel(true, newpage);
            },
            over:function(e){
                if(dvtp == 0){

                    const ele = e.target;
                    const idele = ele.getAttribute('data-plane-id');
                    /* var planetemp = _this.htmlToGl.getPlaneTB(idele);
                    planetemp.mouseEnter(); */
                }
            },
            out:function(e){
                if(dvtp == 0){

                    const ele = e.target;
                    const idele = ele.getAttribute('data-plane-id');
                    /* var planetemp = _this.htmlToGl.getPlaneTB(idele);
                    planetemp.mouseLeave(); */
                }
            }
        });

        btns.addBtn('#sec_cocteleria .btnsnpcarrusel .btn');

        this.resizeCarrusel();
    }


    handleResize(){
        
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        
        if(this.bgmodal){
            this.bgmodal.width = vw;
            this.bgmodal.height = vh;

           this.bgwhite();
        }


        this.resizeCarrusel();
    }

    bgwhite(){
        const vw = window.innerWidth;
        const vh = window.innerHeight;

        var continfo = document.querySelector('.modal_coctail .continfo');

        var elemRect = continfo.getBoundingClientRect();

        if(this.bggrplo){


            const wbg = elemRect.width;

            var newvposy = (elemRect.left/3)*2;
            this.bggrplo.clear();
            
            var posabax = (wbg/3);

            // this.bggrplo.beginFill(0xc97d31);
            // this.bggrplo.moveTo(100, (vh-(posabax+50)));
            // this.bggrplo.lineTo(-posabax, vh);
            // this.bggrplo.lineTo(posabax, vh);
            
            this.bggrplo.beginFill(0xffffff);
            this.bggrplo.moveTo(-newvposy, 0);
            this.bggrplo.lineTo(posabax, vh);
            this.bggrplo.lineTo(wbg, vh);
            this.bggrplo.lineTo(wbg, 0);

            this.bggrplo.closePath();
            this.bggrplo.endFill();

            this.contbggrplo.position.x = elemRect.left;


            this.montanacont.position.x = 0; //(vw-wbg)/2;
            this.montanacont.position.y = vh;

            var vhbgpor = (vh*1)/1110;

            this.montanacont.scale.set(vhbgpor);
            //

            if(vw < 950){

                // if(this.animbotella){
                //     this.animbotella.position.x = (vw/2);
                //     this.animbotella.position.y = vh/3;
                // }

                this.bgcover.anchor.x = .001;

                this.contbggrplo.alpha = 0;
            }else{
                this.contbggrplo.alpha = 1;

                this.bgcover.anchor.x = 0;
                // if(this.animbotella){
                //     this.animbotella.position.x = (vw-wbg)/3;
                //     this.animbotella.position.y = (vh)/2;
                // }
            }

            var porceh = this.scaleimg();
            // this.animbotella.scale.set(porceh);
        }
    }

    scaleimg(){
        const vh = window.innerHeight;
        //440x784
        return (vh*1)/950;
    }

    goOneProd(elementtarget, idcoctail, urlvid, _color, _data){
        var _this = this;

        const urlvidfix = getUrlAssets()+'/video/'+urlvid;


        var rect = elementtarget.getBoundingClientRect();
    
        var fixscro = this.htmlToGl.scrollmov
    
        //console.log('rect.top', rect.top, fixscro.y)
        var planetb = {
          left: rect.left,
          top: rect.top,
          width: rect.width,
          height: rect.height
        };

        this.idselecto = Number(idcoctail);

        var comoes = false;

        var _fixcolor = replaceAll( _color, '0x#', '0x');
        _fixcolor = replaceAll( _fixcolor, '#', '0x');


        const vw = window.innerWidth;
        const vh = window.innerHeight;

        if(this.bgmodal == null){
            //this.modalfix = new PIXI.Container();
            //this.modalfix.addChild(planetb.fixtb);

            this.bgmodal = new PIXI.Graphics();
            this.bgmodal.beginFill(0xffffff);
            this.bgmodal.drawRect(0, 0, 100, 100);
            //this.modalfix.position.x = planetb.left;
            //this.modalfix.position.y = planetb.top;
            //this.pixijsManager.app.stage.addChild(this.group);
            //this.htmlToGl.group
            this.bgmodal.position.x = planetb.left;
            this.bgmodal.position.y = planetb.top;
            this.bgmodal.width = planetb.width;
            this.bgmodal.height = vh;//planetb.height;

            this.htmlToGl.modal.addChild(this.bgmodal);

            comoes = true;
        }
        
        this.bgmodal.tint = _fixcolor;
        
       // this.htmlToGl.modal.addChild(this.modalfix);

        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var modalcoctail = document.querySelector('.modal_coctail');
        var fixmodalcoctail = document.querySelector('.fixmodalcoctail');
        var continfo =  modalcoctail.querySelector('.continfo');
        var closemodal =  document.querySelector('.modal_coctail .closemodal');
        var btnsnp = document.querySelector('.fixmodalcoctail .btnsnp');

        var btnsnpright =  btnsnp.querySelector('.right');
        var btnsnprightline =  btnsnpright.querySelector('.fixlinea .linea');
        btnsnpright.style.color = _color;
        btnsnprightline.style.background = _color;
        
        var continfofix_title =  continfo.querySelector('.continfofix .title');
        var continfofix_desc =  continfo.querySelector('.continfofix .desc');
        var continfofix_info =  continfo.querySelector('.continfofix .fixinfo .info');
        
        continfofix_title.innerHTML = _data.title;
        continfofix_desc.innerHTML = _data.ingredientes;
        continfofix_info.innerHTML = _data.preparacion;
        
        var btncomprar =  continfo.querySelector('.continfofix .txtwhere .btncomprar');

        btncomprar.style.background = _color;

        if(_data.linkcomprar != ''){
            btncomprar.style.display = 'block';
            btncomprar.setAttribute('href', _data.linkcomprar);
        }else{
            btncomprar.style.display = 'none';
        }


        var logococtail =  continfo.querySelector('.continfofix .fixlogo');

        logococtail.innerHTML = '<img src="'+_data.sello+'">';
        
        if(this.contbggrplo){
            this.htmlToGl.modal.removeChild(this.contbggrplo);
        }
        
        this.contbggrplo = new PIXI.Container();
        this.bggrplo = new PIXI.Graphics();
        //this.bggrplo.alpha = 0;
        
        if(comoes){
            this.bggrplo.position.x = vw;

            btnsnp.style.opacity = 0;
        }

        this.contbggrplo.addChild(this.bggrplo);

        var urlcover = _data.bg;//
        const texturebg = PIXI.Texture.from(urlcover);
        

        if(this.montanacont){
            this.htmlToGl.modal.removeChild(this.montanacont);
        }

        this.montanacont = new PIXI.Container();
        this.bgcover = new PIXI.Sprite( texturebg );
        this.bgcover.anchor.y = 1; 
        
        //
        this.montanacont.addChild(this.bgcover);
        this.htmlToGl.modal.addChild(this.montanacont);

        this.bgcover.alpha = 0;

        const loader = new PIXI.Loader(); // you can also create your own if you want

        // Chainable `add` to enqueue a resource
        loader.add(urlcover);
        loader.load((loader, resources) => {

            gsap.to(this.bgcover, {
                // duration: 1,
                delay: .1, alpha: 1});
        });

        this.htmlToGl.modal.addChild(this.contbggrplo);

        //

        var cninfo = continfo.childNodes;



        if(comoes){
            for (let i = 0; i < cninfo.length; i++) {
                const element = cninfo[i];
                
                gsap.set(element, {
                    css: {
                        transform:'translate3D(100px,0,0)',
                        opacity: 0
                    },
                });
            }
        }

        fixmodalcoctail.classList.remove('oculto');
        document.body.classList.add('example');

        this.bgwhite();

        var porceh = this.scaleimg();

        
        if(comoes){
            gsap.set(closemodal, {css:{opacity:0} });
            gsap.to(closemodal, {delay: .5, duration: .8, css:{opacity:1} });
            gsap.to(btnsnp, {delay: .5, duration: .8, css:{opacity:1} });
        }

         
        gsap.to(contsite, {duration: .4, css:{opacity:0} });

        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,-100%,0)'} });
       
        gsap.to(contlogotop, {duration: .4, delay: .1, css:{opacity:0} });

        // gsap.to(this.animbotella, {
        //     // duration: 1,
        //     delay: .3, alpha: 1});

        // gsap.to(this.animbotella.scale, {
        //     // duration: 1,
        //     delay: .3, x: porceh, y: porceh});

        
        // gsap.to(this.bgcover.position, {
        //     // duration: 1,
        //     delay: .4, x: 0});
        
        if(comoes){
            gsap.to(this.bggrplo, {
                    // duration: 1,
                    delay: .3,
                    alpha: 1});
            gsap.to(this.bggrplo.position, {
                    // duration: 1,
                    delay: .3,
                    x: 0});


            for (let i = 0; i < cninfo.length; i++) {
                const element = cninfo[i];
                
                var ndela = .4+(.04*i);

                gsap.to(element, {
                    delay: ndela,
                    css: {
                        transform:'translate3D(0,0,0)',
                        opacity: 1
                    },
                });
            }
        }else{
            var continfo =  modalcoctail.querySelector('.continfo .continfofix');
            var cninfo = continfo.childNodes;
    
            for (let i = 0; i < cninfo.length; i++) {
                const element = cninfo[i];
                
                var ndela = .4+(.04*i);
    
                gsap.to(element, {
                    delay: ndela,
                    css: {
                        opacity: 1
                    },
                });
            }
        }

        //this.bgmodal.tint = _fixcolor;

        gsap.to(this.bgmodal, {
                // duration: 1,
                width: vw,
                height: vh,
                ease: 'power.inOut',
            });

        gsap.to(this.bgmodal.position, {
                // duration: 1,
                x: 0,
                y: 0,
                ease: 'power.inOut',
                onComplete: function(){

                    document.querySelector('.modal_coctail').classList.add('menuscrolla');
                    
                }
            });

        
        setTimeout(function(){
            
        }.bind(this), 1000);
        

        var btnsclose = new BtnTropical(function(){
            return this.bloqueobtns;
        }.bind(this),{
            click:function(e){
                    // planetb.addChild(planetb.fixtb);
               _this.byebyeModal();
            },
            over:function(e){
                
            },
            out:function(e){
                
            }
        });

        btnsclose.addBtn('.modal_coctail .closemodal');

    }

    byebyeModal(){
        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var modalcoctail = document.querySelector('.modal_coctail');
        var fixmodalcoctail = document.querySelector('.fixmodalcoctail');
        var continfo =  modalcoctail.querySelector('.modal_coctail .continfo');
        var closemodal =  document.querySelector('.modal_coctail .closemodal');
        var btnsnp = document.querySelector('.fixmodalcoctail .btnsnp');

        var cninfo = continfo.childNodes;

        const vw = window.innerWidth;
        const vh = window.innerHeight;

        gsap.to(btnsnp, {duration: .4, css:{opacity:0} });
        gsap.to(closemodal, {duration: .4, css:{opacity:0} });

        for (let i = 0; i < cninfo.length; i++) {
            const element = cninfo[i];
            
            var ndela = (.02*i);

            var _paramt = {
                delay: ndela,
                duration: .5,
                css: {
                    transform:'translate3D(200px,0,0)',
                    opacity: 0
                },
            };

            if(i >= (cninfo.length-1)){
                _paramt.onComplete = function(){

                    this.htmlToGl.modal.removeChild(this.contbggrplo);
                    this.htmlToGl.modal.removeChild(this.bgmodal);
                    this.htmlToGl.modal.removeChild(this.montanacont);
                    
                    this.bgmodal = null;
                    this.bggrplo = null;
                    this.montanacont = null;

                    fixmodalcoctail.classList.add('oculto');
                }.bind(this);
            }
            gsap.to(element, _paramt);
        }

        document.querySelector('.modal_coctail').classList.remove('menuscrolla');
                    
        document.body.classList.remove('example');
        window.scrollTo(0, this.htmlToGl.scrollmov.target);

        // gsap.to(this.animbotella, {
        //     duration: .4,
        //     delay: .2, alpha: 0});

        // gsap.to(this.animbotella.scale, {
        //     duration: .4,
        //     delay: .18, x: 2, y: 2});

        // var vidcont =  document.querySelector('.modal_coctail .vid_cont');
        // gsap.to(vidcont, {duration: .8, delay: .1, css: {opacity:0}, onComplete:function(){
        //     vidcont.innerHTML = '';
        // }});
        
        gsap.to(this.bgcover, {
            // duration: 1,
            delay: .14, alpha: 0});

        // gsap.to(this.bgcover.position, {
        //     // duration: 1,
        //     delay: .14, x: vw});

        gsap.to(this.bgmodal, {
            duration: 1,
            delay: .2,
            alpha: 0});

        gsap.to(this.bggrplo.position, {
            duration: 1,
            delay: .2,
            x: (vw)});

        //this.htmlToGl.modal.removeChild(this.modalfix);
        gsap.to(contsite, { delay: .3, css:{opacity:1} });
        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,0,0)'} });
       
        gsap.to(contlogotop, {duration: .4, delay: .6, css:{opacity:1}, onComplete:function(){
            
            

        }.bind(this) });

        
    }
}

export default Coctail;