import BtnTropical from "../tropical/utils/BtnTropical";
import { validateEmail } from "../tropical/utils/Utils";

class Contacto {

    constructor(){

        var _this = this;

        var btns = new BtnTropical(function(){
            return false;
        }.bind(this),{
            click:function(e){
                _this.sendemail();
            }
        });



        btns.addBtn('#sec_contacto .btnenviar');

    }


    sendemail(){

        var name = document.querySelector('#sec_contacto #contact_name').value;
        var subj = document.querySelector('#sec_contacto #contact_sub').value;
        var email = document.querySelector('#sec_contacto #contact_email').value;
        var desc = document.querySelector('#sec_contacto #contact_desc').value;
        var alerta = document.querySelector('#sec_contacto .alerta');

        alerta.innerHTML = '';

        if(name != '' && sub != '' && validateEmail(email) && desc != ''){
            var datasend = {
                name: name,
                subj: subj,
                email: email,
                desc: desc,
            };

            fetch('/contact_email.php', {
                method: 'POST',
                body: JSON.stringify(datasend)
            }).then((response) => {

                if (response.ok) {
                    alerta.innerHTML = 'Mensaje enviado!';

                    document.querySelector('#sec_contacto #contact_name').value = '';
                    document.querySelector('#sec_contacto #contact_sub').value = '';
                    document.querySelector('#sec_contacto #contact_email').value = '';
                    document.querySelector('#sec_contacto #contact_desc').value = '';
                }
            })
        }else{
            alerta.innerHTML = 'Completa todos los datos!';
        }

        
    }

}


export default Contacto;