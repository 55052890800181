import BtnTropical from "../tropical/utils/BtnTropical";
import { getUrlAssets } from "../tropical/utils/Utils";

class Home {

    constructor(_pixijsManager, _htmlToGl, _endcallbackintro){

        var _this = this;
        this.pixijsManager = _pixijsManager;
        this.htmlToGl = _htmlToGl;

        const strfindbtn = '#sec_origen [data-plane-id]';

        var bgtop = document.querySelector(strfindbtn);
        
        var idbgtop = bgtop.getAttribute('data-plane-id');
        var planetemp = _this.htmlToGl.getPlaneTB(idbgtop);

        planetemp.introtop();
        
        var container3d = document.querySelector('#container3d');
        gsap.set(container3d, {css: {transform:'translate3D(0,-200px,0)', opacity: 0 } });

        gsap.to(container3d, {
            delay: .3,
            duration: 1.3,
            css: {
                transform:'translate3D(0,0,0)',
                opacity: 1
            },
        });

        var desc = document.querySelector('#sec_origen .fixdec .desc');

        gsap.set(desc, {css: {transform:'translate3D(0,200px,0)', opacity: 0 } });

        gsap.to(desc, {
            delay: .2,
            duration: 1.3,
            css: {
                transform:'translate3D(0,0,0)',
                opacity: 1
            },
            onComplete:function(){
                _endcallbackintro();
            }
        });

        window.addEventListener('resize', this.handleResize.bind(this), false);

        this.mousemove();
    }

    mousemove(){
                // Init
        var container = document.getElementById("container3d"),
        inner = document.getElementById("inner");

        // Mouse
        var mouse = {
            _x: 0,
            _y: 0,
            x: 0,
            y: 0
        }

        function updatePosition(event) {
            var e = event || window.event;
            mouse.x = e.clientX - mouse._x;
            mouse.y = (e.clientY - mouse._y) * -1;
        }

        function setOrigin(e) {
            mouse._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
            mouse._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
        }

        function show() {
            return "(" + mouse.x + ", " + mouse.y + ")";
        }

        // Track the mouse position relative to the center of the container.
        setOrigin(container);

        //-----------------------------------------

        var counter = 0;
        var updateRate = 10;
        var isTimeToUpdate = function() {
            return counter++ % updateRate === 0;
        };

        //-----------------------------------------

        var onMouseEnterHandler = function(event) {
            update(event);
        };

        var onMouseLeaveHandler = function() {
            inner.style = "";
        };

        var onMouseMoveHandler = function(event) {
            if (isTimeToUpdate()) {
                update(event);
            }
        };

        //-----------------------------------------

        var update = function(event) {
            updatePosition(event);
            updateTransformStyle(
                (mouse.y / inner.offsetHeight / 2).toFixed(2),
                (mouse.x / inner.offsetWidth / 2).toFixed(2)
            );
        };

        var updateTransformStyle = function(x, y) {
            var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
            inner.style.transform = style;
            inner.style.webkitTransform = style;
            inner.style.mozTransform = style;
            inner.style.msTransform = style;
            inner.style.oTransform = style;
        };

        //-----------------------------------------

        container.onmouseenter = onMouseEnterHandler;
        container.onmouseleave = onMouseLeaveHandler;
        container.onmousemove = onMouseMoveHandler;
    }

    handleResize(){
        
        
    }
}

export default Home;