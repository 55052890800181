import BtnTropical from '../tropical/utils/BtnTropical';
import {getDistance} from '../tropical/utils/MathUtils';

class Menupisco {
    constructor(_html5gl){
        var _this = this;
 
        this.htmlgl = _html5gl;
        this.bart = document.querySelector( '.menu .fixitems .line' );

        window.addEventListener('resize', this.handlerresize.bind(this));

        this.handlerresize();

        this.bloqueobtns = false;
        this.openmenustate = false;
        this.animenus = false;

        var btnopen = new BtnTropical(function(){
            return false;
        }.bind(this),{
            click:function(e){
                if(!_this.openmenustate && !_this.animenus){
                    _this.openMenu();
                }
            }
        });

        btnopen.addBtn('.menu .menulines');

        var btnclose = new BtnTropical(function(){
            return false;
        }.bind(this),{
            click:function(e){
                if(_this.openmenustate && !_this.animenus){
                    _this.closeMenu();
                }
            }
        });

        btnclose.addBtn('.menu .closemenu');


        var btnmenus = new BtnTropical(function(){
            return false;
        }.bind(this),{
            click:function(e){
                const ele = e.target;
                const idele = ele.getAttribute('data-menuanchor');

                const vw = window.innerWidth;

                if(vw < 950)
                    _this.closeMenu(idele);
                else
                    _this.goSection(idele);

            },
            over:function(e){
                
            },
            out:function(e){
                
            }
        });

        btnmenus.addBtn('.menu .item');


        var menutop = document.querySelector('.menu');
        menutop.classList.add('menuclose');
        var items = menutop.querySelector('.items');
        items.classList.add('extraitems');
        items.classList.remove('oculto');
    }
    
    goSection(idele){
        var _this = this;
        const contsection= document.querySelectorAll('[data-scroll-section]');

        for (let i = 0; i < contsection.length; i++) {
            const element = contsection[i];
            const idsec = element.getAttribute('id');

            var sinsec = '';

            if(idsec)
                sinsec = idsec.replace('sec_','');

            if(idsec && sinsec == idele){
                var recta = element.getBoundingClientRect();
                //console.log(idsec,_this.htmlgl.scrollmov.currentRounded, recta, window.scrollY);


                _this.htmlgl.elscroll.scrollTo(element, {duration: 0, disableLerp: true, offset: -80});

                // var posfix = (recta.y+_this.htmlgl.scrollmov.currentRounded)-80;

                // if(idele == 'origen')
                //     posfix = 1;

                // window.scrollTo(0, posfix);
                break;
            }
        }
    }

    clearstyles(){

        var menutop = document.querySelector('.menu');
        var items = menutop.querySelector('.items');
        const childernitems = document.querySelectorAll('.menu .item');
        
        for (let i = 0; i < childernitems.length; i++) {
            const element = childernitems[i];
            element.setAttribute('style', '');
        }
    }

    closeMenu(_idsectiongo){
        var _this = this;

        this.animenus = true;


        var menutop = document.querySelector('.menu');
        var socialcont = menutop.querySelector('.social');

        socialcont.classList.remove('extrasocial');
        var menulines = menutop.querySelector('.menulines');
        var btnclosemenu = menutop.querySelector('.closemenu');
        var items = menutop.querySelector('.items');
        items.classList.add('extraitems');
        var fixlogo = menutop.querySelector('.fixlogo');
        fixlogo.classList.remove('extralogo');
        var logoadcedad = fixlogo.querySelector('.logoadcedad');

        btnclosemenu.classList.add('oculto');
        menulines.classList.remove('oculto');

        menutop.classList.remove('menuscrolla');
        menutop.classList.add('menuclose');

        gsap.set(menulines, { css: { opacity: 0, transform: 'translate3d(0, -100px, 0)' } });
        gsap.to(menulines, { duration: .4, css: { opacity: 1, transform: 'translate3d(0, -10px, 0)' } });
       
        gsap.to(menutop, { duration: .5, css: { height: '5rem' }});


        const childernitems = items.childNodes;
        
        for (let i = 0; i < childernitems.length; i++) {
            const element = childernitems[i];

            var ndela = i*.04;

            var _paramani = { delay: ndela, duration: .2, css: { opacity: 0, transform: 'translate3d(0, 100%, 0)' } };

            if(i >= (childernitems.length-1)){
                _paramani.onComplete = function(){
                    //items.classList.add('oculto');


                    setTimeout(function(){
                        _this.clearstyles();
                    },500);
                }
            }

            gsap.to(element, _paramani);
           
        }

        ///

        menutop.scrollTo(0,1);

        document.body.classList.remove('example');

        if(_idsectiongo != undefined){

            setTimeout(function(){
                _this.goSection(_idsectiongo);
            },300);
        }


        gsap.to(logoadcedad, { duration: .5, css: { opacity: 0, transform: 'translate3d(-50%, -200%, 0)' } });
        gsap.to(fixlogo, { duration: .5, css: { top: '1.15rem' }, 
        onComplete:function(){

            _this.openmenustate = false;
            _this.animenus = false;

        }  });
    }

    openMenu(){
        this.animenus = true;
        this.openmenustate = true;

        var _this = this;
        
        document.body.classList.add('example');

        var menutop = document.querySelector('.menu');

        menutop.classList.remove('menuclose');

        var socialcont = menutop.querySelector('.social');

        socialcont.classList.add('extrasocial');

        var menulines = menutop.querySelector('.menulines');
        var btnclosemenu = menutop.querySelector('.closemenu');
        var items = menutop.querySelector('.items');
        var fixlogo = menutop.querySelector('.fixlogo');
        fixlogo.classList.add('extralogo');


        var logoadcedad = fixlogo.querySelector('.logoadcedad');
        menulines.classList.add('oculto');
        btnclosemenu.classList.remove('oculto');

        gsap.set(btnclosemenu, { css: { opacity: 0, transform: 'translate3d(0, -100px, 0)' } });
        gsap.to(btnclosemenu, { duration: .4, css: { opacity: 1, transform: 'translate3d(0, 0, 0)' } });
       
        gsap.to(menutop, { duration: .5, css: { height: '100%' } });

        gsap.set(logoadcedad, { css: { opacity: 0, transform: 'translate3d(-50%, -200%, 0)' } });
        gsap.to(logoadcedad, { duration: .5, css: { opacity: 1, transform: 'translate3d(-50%, -100%, 0)' } });
        gsap.to(fixlogo, { duration: .5, css: { top: '11rem' } });
        
        items.classList.remove('extraitems');
        
        
        const childernitems = items.childNodes;
        
        for (let i = 0; i < childernitems.length; i++) {
            const element = childernitems[i];

            var ndela = i*.05;

            gsap.set(element, { css: { opacity: 0, transform: 'translate3d(0, 100%, 0)' } });

            var _paramani = { delay: ndela, duration: .3, css: { opacity: 1, transform: 'translate3d(0, 0, 0)' } };

            if(i >= (childernitems.length-1)){
                _paramani.onComplete = function(){
                    _this.animenus = false;
                    menutop.classList.add('menuscrolla');
                }
            }

            gsap.to(element, _paramani);
           
        }
    }
    
    render(){

        const vh = window.innerHeight/2;

        const contsection= document.querySelectorAll('[scrollSectionInview]');

        var menuitems = document.querySelectorAll('.menu .fixitems .items .item');
        var itemcont = document.querySelector('.menu .fixitems .items');
        var rectacont = itemcont.getBoundingClientRect();

        var dista = 100000000;
        var idsec = '';
        var sinsec = '';
        for (let i = 0; i < contsection.length; i++) {
            const element = contsection[i];
            
            var elementsiv = element.getAttribute('data-scroll-section-inview');

            var recta = element.getBoundingClientRect();
            
            var distance = getDistance(0,0,recta.top, 0);

            if(dista > distance && elementsiv !== null){
                if(element.getAttribute('id')){
                    dista = distance;
                    idsec = String( element.getAttribute('id') );
                    sinsec = idsec.replace('sec_','');
                }
            }
        }


        for (let e = 0; e < menuitems.length; e++) {
            const elementd = menuitems[e];

            const iditem = elementd.getAttribute('data-menuanchor');

            //console.log(idsec);
            if(iditem == sinsec){
                var recta = elementd.getBoundingClientRect();
                elementd.classList.add('active');
                // elementd.left
                var posii = recta.left-rectacont.left;

                this.bart.setAttribute('style', 'left: '+posii+'px; width: '+recta.width+'px');
            }else{
                elementd.classList.remove('active');
            }
            
        }
    }


    handlerresize(){
    }
}

export default Menupisco;