import GlObject from './GlObject';

class PlaneTBProduct extends GlObject { 
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    this.uIndex = index;
    this.typeplane = 'tbproduct';

    this.tbprodid = el.getAttribute('data-product-id');
    var tbtype = el.getAttribute('data-tb-color');

    var fixscro = this.getPosScroll();
    this.lastTopShift = fixscro.speed; 

    // var urlcover = el.getAttribute('data-image0');

    // this.arrimages = [];
    // this.arrimages[0] = PIXI.Texture.from(urlassets+urlcover);

   this.imgdata= {w: 300, h: 300};

  //  this.geometry = new PIXI.Geometry()
  //     .addAttribute('aVertexPosition', // the attribute name
  //         [0, 0, // x, y
  //             this.imgdata.w, 0, // x, y
  //             this.imgdata.w, this.imgdata.h,
  //             0, this.imgdata.h], // x, y
  //         2) // the size of the attribute
  //     .addAttribute('aUvs', // the attribute name
  //         [0, 0, // u, v
  //             1, 0, // u, v
  //             1, 1,
  //             0, 1], // u, v
  //         2) // the size of the attribute
  //     .addIndex([0, 1, 2, 0, 2, 3])
  //     .interleave();

  //   this.shader = PIXI.Shader.from( Zero2.vertexShader, Zero2.fragmentShader,
  //   {
  //     uSampler2: PIXI.Texture.from(urlimg),
  //   });

  //this.mesh = new PIXI.Sprite( this.arrimages[0] );
  //this.mesh = new PIXI.Mesh(this.geometry, this.shader);
    
    var arranis = ['especial','reserva','transparente','roble','negra','envejecido', 'cumbre', 'berries' ];
    var arranisrtrans = [
      'Pisco Especial Traslacion_000',
      'Pisco Reserva Traslacion_000',
      'Pisco Transparente Traslacion_000',
      'Pisco Roble Traslacion_000',
      'Traslacion Etiqueta Negra_000',
      'Pisco Envejecido Traslacion_000',
      'Pisco Cumbre Traslacion',
      'Pisco Cherrie Traslacion'
    ];

    var arranisrtransr = [
      'Pisco Especial Rotacion_000',
      'Pisco Reserva Rotacion_000',
      'Pisco Transparente Rotacion_000',
      'Pisco Roble Rotacion_000',
      'Rotacion Etiqueta Negra_000',
      'Pisco Envejecido Rotacion_000',
      'Pisco Cumbre Rotacion',
      'Cherrie Rotacion'
    ];

    // for (let i = 0; i < arranis.length; i++) {
    
    // }

    this.speedani = 0.5;

    const frames = [];
    const framesover = [];

    if(dvtp == 0){

      for (let io = 0; io < 50; io++) {
          const val = io < 10 ? `0${io}` : io;

          var vartempname = arranisrtransr[this.tbprodid];

          // magically works since the spritesheet was loaded with the pixi loader
          frames.push(PIXI.Texture.from(`${vartempname}${val}.png`));
      }
      
      for (let i = 0; i < 20; i++) {
          const val = i < 10 ? `0${i}` : i;

          // magically works since the spritesheet was loaded with the pixi loader
          framesover.push(PIXI.Texture.from(`${arranisrtrans[this.tbprodid]}${val}.png`));
      }

      this.animbotellaover = new PIXI.AnimatedSprite(framesover);
      this.animbotellaover.anchor.set(0.5);
      this.animbotellaover.animationSpeed = 1;
      this.animbotellaover.loop = false;
  
      this.animbotella = new PIXI.AnimatedSprite(frames);
      this.animbotella.anchor.set(0.5);
      this.animbotella.animationSpeed = this.speedani;
      this.animbotella.play();
    }
   
    this.isover = false;
    this.goloop = false;
    
    this.bggr = new PIXI.Graphics();
   
    this._color = '0x'+tbtype;

    this.bggr.beginFill(this._color);
    this.bggr.drawRect(0, 0, 100, 100);
    
    this.bggr.endFill();
    this.addChild(this.bggr);

    this.fixtb = new PIXI.Container();
    this.addChild(this.fixtb);


    var _this = this;
    
    if(this.animbotella){

      _this.fixtb.addChild(_this.animbotella);

      _this.animbotella.onLoop = function () {
        // finished!

        if(_this.isover){
          _this.goloop = true;
          _this.animbotella.stop();

          _this.fixtb.removeChild(_this.animbotella);
          _this.fixtb.addChild(_this.animbotellaover);

          _this.animbotellaover.play();
        }
      }
    }

    this.movichi = false;
  }

  addEvents() {
    this.mouseEnter();
    this.mouseLeave();
  }

  mouseEnter() {

    if(this.animbotella){

      this.animbotella.animationSpeed = 1;
    
      this.isover = true;

    if(this.isbackover){
        this.isbackover = false;
        this.animbotellaover.play();
      }
    }

    //this.animbotella.loop = false;

    // if(this.material){
    //   this.setNoiseParam();

    //   this.material.uniforms.uActive.value = 1;
    //   gsap.to(this.material.uniforms.uProg, {
    //     // duration: 1,
    //     value: 0.25,
    //     ease: 'power.inOut',
    //   });
    // }
   
  }

  mouseLeave() {
    
    this.isover = false;

    if(this.goloop){

      //this.animbotella.stop();
      //this.animbotellaover.stop();
      this.isbackover = true;

    }else{

      if(this.animbotella)
        this.animbotella.animationSpeed = this.speedani;
    }
     
    // this.fixtb.removeChild(this.animbotella);
    // this.fixtb.addChild(this.animbotellaover);

    
    // if(this.material){
    //   this.material.uniforms.uActive.value = 0;
    //   gsap.to(this.material.uniforms.uProg, {
    //       // duration: 1,
    //       value: 0,
    //       ease: 'power.inOut',
    //     });
    // }
  }

  updateTime(){


    if(this.animbotella){

      if(!this.isover && this.isbackover){
        var menosframe = this.animbotellaover.currentFrame-1;
  
        if(menosframe >= 0){
          this.animbotellaover.gotoAndStop(menosframe);
        }else if(menosframe < 0){
          this.isbackover = false;
          this.goloop = false;
          this.fixtb.removeChild(this.animbotellaover);
          this.fixtb.addChild(this.animbotella);
          this.animbotella.animationSpeed = this.speedani;
          this.animbotella.gotoAndPlay(0);
        }
      }
    }

    // if(dvtp != 0){
    //   const vh = window.innerHeight/2;
    //   var rec = this.el.getBoundingClientRect();
    
    //   var fixscro = this.getPosScroll();
  
    //   var masi = rec.top;
    //   if(!this.movichi){
    //     if(masi < vh && masi > -100){
    //       this.movichi = true;
    //       this.mouseEnter();
    //     }else{
    //       this.movichi = true;
    //     }
    //   }else if(this.movichi){
    //     if(masi >= vh || masi <= -100){
    //       this.movichi = false;
    //       this.mouseLeave();
    //     }else{
    //       this.movichi = false;
    //     }
    //   }
    // }

    super.updateTime();
  }


  goIntro(_delay, _callback){
    //
  }


  goBye(_delay, _callback){
    // this.material.uniforms.uActive.value = 1;
  
    if(this.interimg)
      clearInterval(this.interimg);

    gsap.to(this, {
      delay: _delay,
      duration: .25,
      alpha: 0,
      ease: 'power.inOut',
      onComplete:function(){

        // if(this && this.material){
        //     this.material.uniforms.uActive.value = 0;
        // }

        if(_callback)
          _callback();
      }.bind(this),
    });
    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: -1,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 1,
    //   });
  }

  resize(){
    super.resize();

    var hh = this.bounds.height;
    var ww = this.bounds.width;

    const vw = window.innerWidth;
    const vh = window.innerHeight;

    this.resizeFixTexture();

    if(this.animbotella){

      if(this.fixtb){

        if(vw < 950){
  
            this.animbotellaover.scale.set(1);
            this.animbotella.scale.set(1);
        }else{
          
          this.animbotellaover.scale.set(.8);
          this.animbotella.scale.set(.8);
        }
  
        if(hh < 380){
          this.fixtb.scale.set(.8);
        }else{
          this.fixtb.scale.set(1);
        }
      }

    }

    
  }

  resizeFixTexture(){
    if(this.bggr){
      var hh = this.bounds.height;
      var ww = this.bounds.width;

      var hhb = this.bounds.height-30;
      var wwb = this.bounds.width-30;

      var vW = 1;
      var vH = 1;
      var vWb = 1;
      var vHb = 1;

      if(ww > hh){
        vH = ( (hh*1)/ww );
        vHb = ( (hhb*1)/(wwb) );
      }else{
        vW = ( (ww*1)/hh );
        vWb = ( (wwb*1)/(hhb) );
      }

      // this.material.uniforms.u_adjust_uv.value = new THREE.Vector2(vW, vH);
      // this.material.uniforms.u_adjust_uv_border.value = new THREE.Vector2(vWb, vHb);


      this.bggr.width = ww;
      this.bggr.height = hh;

      if(this.fixtb){
        this.fixtb.position.x = (ww/2);
        this.fixtb.position.y = (hh/2)+10;
      }

      // this.mesh.width = ww-40;
      // this.mesh.height = ww-40;

      // this.mesh.position.x = 20;
      // this.mesh.position.y = 20;

    }
  }

  byebye(){
    this.removeChild(this.bggr);
    this.removeChild(this.mesh);

    // this.material.dispose();
    // this.geometry.dispose();
    // this.texture.dispose();

    this.material = undefined;
    this.geometry = undefined;
    this.texture = undefined;
    this.mesh = null;
  }

}

export default PlaneTBProduct;