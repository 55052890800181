import PlaneIMG from "./PlaneIMG";

class PlaneTB extends PlaneIMG {
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    el.setAttribute('data-btn-cover-gl', index);

    
    this.addEvents(); 
  }

  addEvents() {
    this.mouseEnter();
    this.mouseLeave();
  }

  mouseEnter() {
   // console.log('over');

    if(this.material){
      this.setNoiseParam();

      this.material.uniforms.uActive.value = 1;
      gsap.to(this.material.uniforms.uProg, {
        // duration: 1,
        value: 0.25,
        ease: 'power.inOut',
      });
    }
   
  }

  mouseLeave() {

    if(this.material){
      this.material.uniforms.uActive.value = 0;
      gsap.to(this.material.uniforms.uProg, {
          // duration: 1,
          value: 0,
          ease: 'power.inOut',
        });
    }
  }
}

export default PlaneTB;