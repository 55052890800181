import BtnTropical from "../tropical/utils/BtnTropical";
import { getUrlAssets } from "../tropical/utils/Utils";

class Galeria {

    constructor(_pixijsManager, _htmlToGl){

        var _this = this;
        this.pixijsManager = _pixijsManager;
        this.htmlToGl = _htmlToGl;

        const strfindbtn = '.galeriacont [data-plane-id]';

        this.bloqueobtns = false;
        var btns;

        if(dvtp == 0){
            btns = new BtnTropical(function(){
                return this.bloqueobtns;
            }.bind(this),{
                click:function(e){
                    const element = e.target;
                    const id = element.getAttribute('data-plane-id');
                    const idgale = element.getAttribute('data-gale-id');
                    const assetsimg = element.getAttribute('data-media');
                    const assetsvid = element.getAttribute('data-vid');

                    _this.goOneProd(id, idgale, assetsimg, assetsvid);
                },
                over:function(e){
                    const ele = e.target;
                    const idele = ele.getAttribute('data-plane-id');
                    var planetemp = _this.htmlToGl.getPlaneTB(idele);
                    planetemp.mouseEnter();
                },
                out:function(e){
                    const ele = e.target;
                    const idele = ele.getAttribute('data-plane-id');
                    var planetemp = _this.htmlToGl.getPlaneTB(idele);
                    planetemp.mouseLeave();
                }
            });
        } else {

            btns = new BtnTropical(function(){
                return this.bloqueobtns;
            }.bind(this),{
                click:function(e){
                    const element = e.target;
                    const id = element.getAttribute('data-plane-id');
                    const idgale = element.getAttribute('data-gale-id');
                    const assetsimg = element.getAttribute('data-media');
                    const assetsvid = element.getAttribute('data-vid');

                    _this.goOneProd(id, idgale, assetsimg, assetsvid);
                }
            });
        }

        _this.yeye = document.querySelectorAll('#sec_galeria .fixcontgalera .galeriacont .itemgale');
        
        this.confignextprev();

        btns.addBtn(strfindbtn);

        window.addEventListener('resize', this.handleResize.bind(this), false);
    }


    confignextprev(){

        var _this = this;

        var btns = new BtnTropical(function(){
            return false;
        }.bind(this),{
            click:function(e){
                const ele = e.target;
                const dataside = ele.getAttribute('data-side');
                console.log(dataside, _this.idselecto)

                var _idgale = _this.idselecto+1;
                var nle = Number(_this.yeye.length);

                if(dataside == 'left'){
                    _idgale = _this.idselecto-1;

                    if( _idgale < 0 ){
                        _idgale = nle-1;
                    }

                }else{

                    if( _idgale >= (nle) ){
                        _idgale = 0;
                    }
                }

                const eleidprod = _this.getidplane(_idgale);

                const id = eleidprod.getAttribute('data-plane-id');
                const idgale = eleidprod.getAttribute('data-gale-id');
                const assetsimg = eleidprod.getAttribute('data-media');
                const assetsvid = eleidprod.getAttribute('data-vid');

                _this.goNP(id, idgale, assetsimg, assetsvid);
            }
        });

        btns.addBtn('.fixmodalgaleria .btnsnp .btn');
    }

    getidplane(_idgale){
        const arrchild = document.querySelectorAll('#sec_galeria .fixcontgalera .galeriacont .itemgale');

        //ele.getAttribute('data-plane-id');
        var idreturn = null;
        for (let i = 0; i < arrchild.length; i++) {
            const element = arrchild[i];
            var idgale = element.getAttribute('data-gale-id');

            if(_idgale == idgale){
                idreturn = element;
                break;
            }
        }

        return idreturn;
    }

    goNP(id, idgale, assetsimg, assetsvid){
        var _this = this;

        var modalprod = document.querySelector('.modal_galeria');
        var continfo =  modalprod.querySelector('.continfo');

        gsap.to(continfo, {
            // duration: 1,
            css:{opacity:0},
            onComplete:function(){
                console.log('yeah final')
                _this.goOneProd(id, idgale, assetsimg, assetsvid);
            }    
        });

    }

    handleResize(){
        
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        
        if(this.bgmodal){
            this.bgmodal.width = vw;
            this.bgmodal.height = vh;

        }
    }

    scaleimg(){
        const vh = window.innerHeight;
        //440x784
        return (vh*1)/950;
    }

    goOneProd(id, idgale, assetsimg, assetsvid){
        var _this = this;
       
        var planetb = this.htmlToGl.getPlaneTB(id);

        this.idselecto = Number(idgale);

        var tbpos = planetb.position;
        var comoes = false;

        if(this.bgmodal == null){
            this.modalfix = new PIXI.Container();
            //this.modalfix.addChild(planetb.fixtb);

            this.bgmodal = new PIXI.Graphics();
            this.bgmodal.beginFill(planetb._color);
            this.bgmodal.drawRect(0, 0, 100, 100);
            this.modalfix.position.x = tbpos.x;
            this.modalfix.position.y = tbpos.y;
            //this.pixijsManager.app.stage.addChild(this.group);
            //this.htmlToGl.group
            this.bgmodal.position.x = tbpos.x;
            this.bgmodal.position.y = tbpos.y;
            this.bgmodal.width = planetb.bggr.width;
            this.bgmodal.height = planetb.bggr.height;

            this.htmlToGl.modal.addChild(this.bgmodal);
            // this.htmlToGl.modal.addChild(this.modalfix);
            comoes = true;
        }
        
        const vw = window.innerWidth;
        const vh = window.innerHeight;

        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var modalprod = document.querySelector('.modal_galeria');
        var fixmodalgaleria = document.querySelector('.fixmodalgaleria');
        var continfo =  modalprod.querySelector('.continfo');
        var closemodal =  document.querySelector('.modal_galeria .closemodal');
        var divbgmodal =  document.querySelector('.modal_galeria .bgmodal');
        var btnsnp = document.querySelector('.fixmodalgaleria .btnsnp');
        
        if(!assetsvid){
            this.loadimg(assetsimg);
        }else{
            this.loadvid(assetsvid);
        }

        continfo.style.opacity = 0;
        divbgmodal.style.opacity = 0;


        if(this.contbggrplo){
            this.htmlToGl.modal.removeChild(this.contbggrplo);
        }

        this.contbggrplo = new PIXI.Container();
        this.bggrplo = new PIXI.Graphics();
        //this.bggrplo.alpha = 0;

        if(comoes){
            this.bggrplo.position.x = vw;
        }
        
        this.contbggrplo.addChild(this.bggrplo);

        this.htmlToGl.modal.addChild(this.contbggrplo);

        fixmodalgaleria.classList.remove('oculto');
        document.body.classList.add('example');

        var porceh = this.scaleimg();

        if(comoes){
            btnsnp.style.opacity = 0;

            gsap.set(closemodal, {css:{opacity:0} });
            gsap.to(closemodal, {delay: .5, duration: .8, css:{opacity:1} });

            gsap.to(btnsnp, {delay: .5, duration: .8, css:{opacity:1} });
        }

        gsap.to(contsite, {duration: .4, css:{opacity:0} });

        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,-100%,0)'} });
       
        gsap.to(contlogotop, {duration: .4, delay: .1, css:{opacity:0} });
        

        if(comoes){
            gsap.to(this.bggrplo, {
                    // duration: 1,
                    delay: .3,
                    alpha: 1});

            gsap.to(this.bggrplo.position, {
                    // duration: 1,
                    delay: .3,
                    x: 0});
        }

        gsap.to(this.bgmodal, {
                // duration: 1,
                width: vw,
                height: vh,
                ease: 'power.inOut'
            });

        gsap.to(divbgmodal, {
                // duration: 1,
                delay:.6,
                css:{opacity:1}
            });

        gsap.to(continfo, {
                // duration: 1,
                delay:.7,
                css:{opacity:1}
            });

        gsap.to(this.bgmodal.position, {
                // duration: 1,
                x: 0,
                y: 0,
                ease: 'power.inOut',
            });

        setTimeout(function(){
            
        }.bind(this), 1000);
        

        var btnsclose = new BtnTropical(function(){
            return this.bloqueobtns;
        }.bind(this),{
            click:function(e){
                    // planetb.addChild(planetb.fixtb);
               _this.byebyeModal();
            },
            over:function(e){
                
            },
            out:function(e){
                
            }
        });

        btnsclose.addBtn('.modal_galeria .closemodal');

    }

    loadvid(_url){

        var urlurl = getUrlAssets()+_url;

        var modalprod = document.querySelector('.modal_galeria');
        var continfo =  modalprod.querySelector('.continfo');
        continfo.innerHTML = '';

        var obj = document.createElement('video');
        obj.setAttribute('id', 'example_video_test');
        obj.setAttribute('autoplay', true);
        obj.setAttribute('controls', ' ');
        // $(obj).attr('poster', 'http://video-js.zencoder.com/oceans-clip.jpg');
        // $(obj).attr('preload', 'auto');
        // $(obj).attr('data-setup', '{}');

        var source = document.createElement('source');
        source.setAttribute('type', 'video/mp4');
        source.setAttribute('src', urlurl);

        obj.append(source);

        continfo.appendChild(obj);
    }

    loadimg(_url){

        var modalprod = document.querySelector('.modal_galeria');
        var continfo =  modalprod.querySelector('.continfo');

        continfo.innerHTML = '<img src="'+_url+'"/>';
    }

    byebyeModal(){
        var _this = this;
        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var modalprod = document.querySelector('.modal_galeria');
        var fixmodalgaleria = document.querySelector('.fixmodalgaleria');
        var continfo =  modalprod.querySelector('.modal_galeria .continfo');
        var closemodal =  document.querySelector('.modal_galeria .closemodal');
        var divbgmodal =  document.querySelector('.modal_galeria .bgmodal');
        var btnsnp = document.querySelector('.fixmodalgaleria .btnsnp');

        const vw = window.innerWidth;
        const vh = window.innerHeight;

        gsap.to(divbgmodal, {
            duration: .4,
            css:{opacity:0}
        });
        
        gsap.to(continfo, {
            duration: .4,
            css:{opacity:0},
            onComplete:function(){
                continfo.innerHTML = "";
            }
        });

        gsap.to(btnsnp, {duration: .4, css:{opacity:0} });
        gsap.to(closemodal, {duration: .4, css:{opacity:0} });

        document.body.classList.remove('example');
        window.scrollTo(0, this.htmlToGl.scrollmov.target);

        gsap.to(this.bgmodal, {
            duration: 1,
            delay: .2,
            alpha: 0});

        gsap.to(this.bggrplo.position, {
            duration: 1,
            delay: .2,
            x: (vw)});

        //this.htmlToGl.modal.removeChild(this.modalfix);
        gsap.to(contsite, { delay: .3, css:{opacity:1} });
        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,0,0)'} });
        gsap.to(contlogotop, {duration: .4, delay: .6, css:{opacity:1}, onComplete:function(){
            
            _this.htmlToGl.modal.removeChild(_this.bgmodal);
            _this.htmlToGl.modal.removeChild(_this.contbggrplo);

            _this.bgmodal = null;
            _this.contbggrplo = null;

            fixmodalgaleria.classList.add('oculto');
        }.bind(this) });

        
    }
}

export default Galeria;