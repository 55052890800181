
import { lerp } from "../utils/MathUtils";
import PlaneBgSesiones from "./PlaneBgSesiones";
import PlaneFooter from "./PlaneFooter";
import PlaneMontain from "./PlaneMontain";
import PlaneTB from "./PlaneTB";
import PlaneTBCoctail from "./PlaneTBCoctail";
import PlaneTBContenido from "./PlaneTBContenido";
import PlaneTBProduct from "./PlaneTBProduct";
import PlaneTopIMG from "./PlaneTopIMG";
import PlaneTriangulo from "./PlaneTriangulo";
import LocomotiveScroll from 'locomotive-scroll';


 class HTMLtoGL {
     constructor(_pixijsManager, _delay, _callbackLoadAll){

        this.curheioff = 1;

        this.scrollmov = {
            y: 0,
            target: 0,
            current: 0,
            currentRounded: 0,
            ease: 0.206
        }

        this.pixijsManager = _pixijsManager;

        this.renderiarHTML();


        this.goIntro(_delay);
        _callbackLoadAll();

        this.onScroll = this.setScrollPosition.bind(this);

        //window.addEventListener('scroll', this.onScroll, false );
        window.addEventListener('resize', this.handleResize.bind(this), false);


        this.setScrollPosition();
        this.resize();
     } 

     scrollgo(){

        this.elscroll = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true,
            smartphone: {
                smooth: true
            },
            tablet: {
                smooth: true
            }
        });

        var _this = this;

        this.elscroll.on('scroll', function(args){
            //console.log('scrolla', args);


//            console.log(scroll.scroll.instance.scroll.y);

            _this.setScrollPosition();

        });
        

        //this.elscroll.init();
     }
     
     setScrollPosition(){
        var contsite = document.querySelector('.example');

        // contsite.scrollTop; //
        if(!contsite){
            var _y = 0;
           
            if(this.elscroll){
                _y = this.elscroll.scroll.instance.scroll.y;
            }
            this.scrollmov.target = _y;
            this.scrollmov.y = _y;
        }
    }

     renderiarHTML(){
        this.current = 0;
        this.htmlisgl = false;
        this.planeTB = [];

        this.viewgroup = true;
        
        if(this.group){
            this.removeALLTBS();
        }

        this.group = new PIXI.Container();
        this.pixijsManager.app.stage.addChild(this.group);

        this.modal = new PIXI.Container();
        this.pixijsManager.app.stage.addChild(this.modal);

        var _this = this; 
        var uIndex = 0;

        const contsection= document.querySelectorAll('[data-scroll-section]');

        for (let i = 0; i < contsection.length; i++) {
            const elsec = contsection[i];

            var _scrollTargetView = elsec.id;

            const elements = elsec.querySelectorAll('.img__espacial');
        
            for (let j = 0; j < elements.length; j++) {
                const el = elements[j];
                
                var tbtype = el.getAttribute('data-tb-type');
                var np;
                
                if(tbtype == 'bg_sesiones'){
                    np = new PlaneBgSesiones();
                }else if(tbtype == 'product_tb'){
                    np = new PlaneTBProduct();

                    el.setAttribute('data-plane-id', uIndex);
                }else if(tbtype == 'coctail_tb'){
                    np = new PlaneTBCoctail();

                    el.setAttribute('data-plane-id', uIndex);
                    
                }else if(tbtype == 'contenido_tb'){
                    np = new PlaneTBContenido();
                    el.setAttribute('data-plane-id', uIndex);

                }else if(tbtype == 'top_img'){
                    np = new PlaneTopIMG();
                    el.setAttribute('data-plane-id', uIndex);

                }else  if(tbtype == 'product_tb_triangulo' || tbtype == 'galeria_tb_triangulo' || tbtype == 'galeria_tb_triangulo_bot'){
                    np = new PlaneTriangulo();
                }else if(tbtype == 'montain'){
                    np = new PlaneMontain();
                }else if(tbtype == 'bgfooter'){
                    np = new PlaneFooter();
                }else{
                    np = new PlaneTB();
                }
                
                np.init(uIndex, el, _this.newscene, function(){
                    return _this.scrollmov;
                });
                
                uIndex++;
                //fix top target view
                //if(dvtp == 0){
                    np.setTargetView('.contsite', _scrollTargetView);
                //}

                _this.group.addChild(np);
                _this.planeTB.push(np);

                //
                el.classList.add("noimg");
            }
        }
        
        _this.htmlisgl = true;

        
        // SPEED DIV !!
        
        const fixspeed = document.querySelectorAll('[data-scroll-desk-speed]');

        for (let i = 0; i < fixspeed.length; i++) {
            const element = fixspeed[i];
            var speedtemp = element.getAttribute('data-scroll-desk-speed');

            if(dvtp == 0){
                element.setAttribute('data-scroll-speed', speedtemp);
            }

            element.removeAttribute('data-scroll-desk-speed');
            
        }
     }

     goIntro(_delayA){
        if(this.htmlisgl){

            if(!_delayA){
                _delayA = 0;
            }

            for (let i = 0; i < this.planeTB.length; i++) {

                const element = this.planeTB[i];

                var dela = _delayA + (i*0.2);

                element.goIntro(dela);
            }
        }
     }

     goBye(_delayA, _callBacka){
        if(this.htmlisgl){

            if(!_delayA){
                _delayA = 0;
            }

            for (let i = 0; i < this.planeTB.length; i++) {

                const element = this.planeTB[i];

                var dela = _delayA + (i*0.1);

                if(i == (this.planeTB.length-1)){
                    element.goBye(dela, function (){
                        if(_callBacka)
                            _callBacka();
                    });


                }else{
                    element.goBye(dela);
                }
            }
        }
     }

     getPlaneTB(_id){

        if(this.htmlisgl){
            for (let i = 0; i < this.planeTB.length; i++) {
                const element = this.planeTB[i];

                if(element.uIndex == _id){
                    return element;
                }
            }
        }

        return false;
     }

     isMenuOpen(_val){
        if(_val){
            this.viewgroup = false;
            this.pixijsManager.app.stage.removeChild(this.group);
        }else{
            this.viewgroup = true;
            this.pixijsManager.app.stage.addChild(this.group);
            this.render();
            this.resize();
        }
     }

    render(){
        //let elapsed = this.pixijsManager.clock.getElapsedTime();

        var contsite = document.querySelector('#fullpage');

        let elapsed = 0;
        if(this.curheioff != contsite.offsetHeight){
            this.handleResize();
        }
        const contsection= document.querySelectorAll('[data-scroll-section]');

        const vw = window.innerWidth;
        const vh = window.innerHeight;

        for (let i = 0; i < contsection.length; i++) {
            const element = contsection[i];
            var recta = element.getBoundingClientRect();
            
            if(recta.top > -recta.height && recta.top < vh){
                element.setAttribute('scrollSectionInview', true);
            }else{
                element.removeAttribute('scrollSectionInview');
            }
        }

        if(this.htmlisgl && this.viewgroup){

            //var elscroll = this.scrollmov.y;

            // var maxtime = 6500;

            // const b = this.current - this.scrollmov.y;
            // var diff = Math.sqrt( b*b );

            // const acc = diff / .06; 
            // var time = maxtime * acc;
            // const velo =+ acc;
        
            // this.current += acc;

            // if(this.current > this.scrollmov.y){
            //     this.current = this.scrollmov.y;
            // }
            
            // this.scrollmov.current = lerp(this.scrollmov.current, this.scrollmov.target, this.scrollmov.ease);
            // this.scrollmov.currentRounded = Math.round(this.scrollmov.current * 100) / 100;
    
            //contsite.style.transform = `translate3d(0, -${this.scrollmov.currentRounded}px, 0)`;

            if(dvtp != 0){
            //    elscroll = this.current;
            }

            for (let i = 0; i < this.group.children.length; i++) {
                const plane = this.group.children[i];
                plane.updatePosition(this.scrollmov.target); //elscroll);
                plane.updateTime(elapsed);
            }

            if(this.renderxtra)
                this.renderxtra();
        }
    }

    handleResize(){
        this.resize();
    }

    resize(){
        this.setHtmlHight(); 
        
        var contsite = document.querySelector('#fullpage');
        this.curheioff = contsite.offsetHeight;

        this.setScrollPosition();


        var _y = window.scrollY;

        if(this.htmlisgl && this.viewgroup){
            for (let i = 0; i < this.group.children.length; i++) {
                const plane = this.group.children[i];
                //
                
                plane.resize();
            }
        }

        if(this.elscroll)
            this.elscroll.update();

        // var asd = document.querySelector('#console');
        // const vh = window.innerHeight;

        // //asd.innerHTML= String("h:"+contsite.offsetHeight+"/ vh:"+vh+" / y:"+_y);

    }

    setHtmlHight(){
        var contsite = document.querySelector('#fullpage');
        //document.body.style.height = `${contsite.offsetHeight}px`;
    }

    removeALLTBS(){
        while(this.group.children.length > 0){ 
            this.group.children[0].byebye();
            this.group.removeChild(this.group.children[0]); 
        }
    }

    bye(){
        
        if(this.htmlisgl){
            
            this.removeALLTBS();
    
            this.pixijsManager.app.stage.removeChild(this.group);

            this.group = null;
            //this.pixijsManager.render();
        }

        this.htmlisgl = false;
    }
 }

 export default HTMLtoGL;