import { getUrlAssets } from '../utils/Utils';
import GlObject from './GlObject';

class PlaneTopIMG extends GlObject { 
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    this.uIndex = index;

    var fixscro = this.getPosScroll();
    this.lastTopShift = fixscro.speed; 


    this.imgdata= {w: 300, h: 300};

    this.widthmonta = 1920;
    var urlcover = getUrlAssets()+'/img/monta.png';//
    const texturebg = PIXI.Texture.from(urlcover);
    
    this.bgimgtopcont = new PIXI.Container();
    this.bgcoca = new PIXI.Sprite( texturebg );
    this.bgcoca.anchor.set(0, 1);
    this.bgcoca.alpha = .8;
    this.bgimgtopcont.addChild(this.bgcoca);

    this.bggr = new PIXI.Graphics();
    this.addChild(this.bggr);

    const gradTexture = this.createGradTexture();
    this.bggra = new PIXI.Sprite(gradTexture);

    this.addChild(this.bggra);

    this.bggra.mask = this.bggr;

    this.bggrmask = this.bggr.clone();

    this.addChild(this.bggrmask);

    this.addChild(this.bgimgtopcont);

    ///


    this.lineas = [];

    var s = this.lineWidth = .1;
    var c = this.lineColor = "0x"+"012913";

    this.lineascont = new PIXI.Container();

    var arraylines = [{type:'x', v0:20, v1:100}, {type:'x', v0:70, v1:30}, {type:'y', v0:80, v1:60}];
    for (let i = 0; i < arraylines.length; i++) {
      this.lineas[i] = new PIXI.Graphics();
      
      this.lineas[i].lineStyle(s, c);


      var initpos = this.getposli(0, arraylines[i].v0, arraylines[i].type);

      this.lineas[i].moveTo(initpos.x, initpos.y);

      var finpos = this.getposli(100, arraylines[i].v1, arraylines[i].type);
      this.lineas[i].lineTo(finpos.x, finpos.y);
      //this.lineas[i].stroke();
      this.lineascont.addChild(this.lineas[i]);
    }

    this.maskalineas = this.bggr.clone();

    // this.addChild(this.lineascont);
    // this.addChild(this.maskalineas);
    this.lineascont.mask = this.maskalineas;

    ///



    
    this.bgimgtopcont.mask = this.bggrmask;

    this.resizebgtop();

  }

  getposli(_posother, _pos, _type){

    var liposx = _posother;
    var liposy = _posother;

    if(_type == 'x'){
      liposx = _pos;
    }else{
      liposy = _pos;
    }

    return {x: liposx, y:liposy};
  }

  createGradTexture() {
      // adjust it if somehow you need better quality for very very big images
      
      var cvw = 250;
      var cvh = 150;

      const canvas = document.createElement('canvas');
      canvas.width = cvw;
      canvas.height = cvh;

      const ctx = canvas.getContext('2d');

      // use canvas2d API to create gradient
      // const grd = ctx.createLinearGradient(0, 0, quality, 0);
      // grd.addColorStop(0, 'rgba(255, 255, 255, 0.0)');
      // grd.addColorStop(0.3, 'cyan');
      // grd.addColorStop(0.7, 'red');
      // grd.addColorStop(1, 'green');
      var grd = ctx.createRadialGradient((cvw/2), 100, 5, (cvw/2), 100, 100);
      grd.addColorStop(0, '#252525');
      grd.addColorStop(1, '#000000');

      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, 250, 150);

      return PIXI.Texture.from(canvas);
  }

  
  introtop(){
    this.bgcoca.position.y = 900;
    gsap.to(this.bgcoca.position, {
        duration: 1.2,
        y: -150,
        ease: 'power.inOut',
      });
  }

  goIntro(_delay, _callback){
    //console.log('intro tb');
    // this.setNoiseParam();
    // this.material.uniforms.uActive.value = 1;
    // //this.material.uniforms.uProg = 0.25;
    // gsap.set(this.material.uniforms.uProg, { value: 0.5 });


    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: 0,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 0,
    //   });
  }


  goBye(_delay, _callback){
    // this.material.uniforms.uActive.value = 1;
  
    if(this.interimg)
      clearInterval(this.interimg);

    gsap.to(this, {
      delay: _delay,
      duration: .25,
      alpha: 0,
      ease: 'power.inOut',
      onComplete:function(){

        // if(this && this.material){
        //     this.material.uniforms.uActive.value = 0;
        // }

        if(_callback)
          _callback();
      }.bind(this),
    });
    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: -1,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 1,
    //   });
  }

  resize(){
    super.resize();

    this.resizebgtop();

    this.resizeFixTexture();
  }

  resizebgtop(){

    const vw = window.innerWidth;
    const vh = window.innerHeight;

    var scalebg = (1*vw)/this.widthmonta;

    if(this.bgcoca)
      this.bgcoca.scale.set(scalebg);

      if(this.bggr){
        this.bggr.clear();
        this.bggr.beginFill(0xff0000);
    
        this.bggr.moveTo(0, 0);
    
        if(vw < 950){
          
          this.bggr.lineTo(0, 90);
          this.bggr.lineTo(30, 85);
        }else{
    
          this.bggr.lineTo(0, 80);
          this.bggr.lineTo(30, 65);
        }
        
        this.bggr.lineTo(100, 100);
        this.bggr.lineTo(100, 0);
        
        this.bggr.endFill();
      }
  }

  resizeFixTexture(){
    if(this.bggr){
      var hh = this.bounds.height;
      var ww = this.bounds.width;

      var hhb = this.bounds.height-30;
      var wwb = this.bounds.width-30;

      var vW = 1;
      var vH = 1;
      var vWb = 1;
      var vHb = 1;

      if(ww > hh){
        vH = ( (hh*1)/ww );
        vHb = ( (hhb*1)/(wwb) );
      }else{
        vW = ( (ww*1)/hh );
        vWb = ( (wwb*1)/(hhb) );
      }

      // this.material.uniforms.u_adjust_uv.value = new THREE.Vector2(vW, vH);
      // this.material.uniforms.u_adjust_uv_border.value = new THREE.Vector2(vWb, vHb);


      this.bggr.width = ww;
      this.bggr.height = hh;

      this.bggrmask.width = ww;
      this.bggrmask.height = hh;

      this.lineascont.width = ww;
      this.lineascont.height = hh;

      this.maskalineas.width = ww;
      this.maskalineas.height = hh;

      this.bggra.width = ww;
      this.bggra.height = hh;

      if(this.bgimgtopcont)
        this.bgimgtopcont.position.y = hh;
      // this.mesh.width = ww-40;
      // this.mesh.height = ww-40;

      // this.mesh.position.x = 20;
      // this.mesh.position.y = 20;

    }
  }

  byebye(){
    this.removeChild(this.bggr);
    this.removeChild(this.mesh);

    // this.material.dispose();
    // this.geometry.dispose();
    // this.texture.dispose();

    this.material = undefined;
    this.geometry = undefined;
    this.texture = undefined;
    this.mesh = null;
  }

}

export default PlaneTopIMG;