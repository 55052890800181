class BtnTropical {
    constructor(_getAni, _param){
        this.version = '0.2';

        this.timeouttouch = null;
        this.touchin = null;
        this.touchend = null;
        this.funSwipe = null;
        this.istouch = false;
        this.getAni = _getAni;

        if(_param && _param.click)
            this.funClick = _param.click;

        if(_param && _param.over)
            this.funOver = _param.over;
    
        if(_param && _param.out)
            this.funOut = _param.out;

        if(_param && _param.swipe)
            this.funSwipe = _param.swipe;

        this.arrbtns = [];

        this.touchanew = {
            touchstartX: 0,
            touchstartY: 0,
            touchendX: 0,
            touchendY: 0
        }
    }
    

    touchstart(evt){
        var _this = this;

        this.touchanew.touchstartX = evt.changedTouches[0].screenX;
        this.touchanew.touchstartY = evt.changedTouches[0].screenY;

        this.touchin = (evt.timeStamp) ? evt.timeStamp : evt.originalEvent.timeStamp;
        this.istouch = true;
        
        if(this.timeouttouch)
            clearTimeout(this.timeouttouch);
        
        this.timeouttouch = setTimeout(function(){
            _this.over(evt);
        }, 10)
    }

    clickFix(evt){
        if(!evt.target.matches( evt.target.buttonsstr )){
            return 
        }

        this.click(evt);
    }

    click (evt){
        if(!this.getAni()){
            if(dvtp != 0){
                this.touchanew.touchendX = evt.changedTouches[0].screenX;
                this.touchanew.touchendY = evt.changedTouches[0].screenY;

                this.touchend = (evt.timeStamp) ? evt.timeStamp : evt.originalEvent.timeStamp;
                this.over(evt);
            }else{
                this.touchanew.touchendX = evt.screenX;
                this.touchanew.touchendY = evt.screenY;
            }

            if(this.funSwipe){
                const { touchstartX, touchstartY, touchendX, touchendY } = this.touchanew;
                const typeside = this.handleGesture(touchstartX, touchstartY, touchendX, touchendY);

                this.funSwipe(typeside);

                if(this.ismove)
                    return;
            }

            if (evt.type == "click")
                this.istouch = true;
            
            if(this.timeouttouch)
                clearTimeout(this.timeouttouch);
            
            if(this.istouch){
                this.funClick(evt);
            }
        }
    }

    over(evt){
        if(this.funOver)
            this.funOver(evt);
    }

    out(evt){
        if(this.funOut)
            this.funOut(evt);
    }	

    mousedown(evt){
        this.ismove = false;
        this.touchanew.touchstartX = evt.screenX;
        this.touchanew.touchstartY = evt.screenY;
    }

    mousemove(evt){
        const vw = window.innerWidth;
        if(vw < 950)
            this.ismove = true;
    }
    mouseup(evt){

        // if(this.funSwipe){

        //     const { touchstartX, touchstartY, touchendX, touchendY } = this.touchanew;
        //     const typeside = this.handleGesture(touchstartX, touchstartY, touchendX, touchendY);

        //     this.funSwipe(typeside);
        // }
    }

    touchmove(){
        this.istouch = false;
    }

    handleGesture(touchstartX, touchstartY, touchendX, touchendY) {
        const delx = touchendX - touchstartX;
        const dely = touchendY - touchstartY;
        if(Math.abs(delx) > Math.abs(dely)){
            if(delx > 0) return "right"
            else return "left"
        }
        else if(Math.abs(delx) < Math.abs(dely)){
            if(dely > 0) return "down"
            else return "up"
        }
        else return "tap"
    }

    addBtn(_buttons){
        var _this = this;
        var btnstemp = document.querySelectorAll( _buttons );

        for (const elebtn of btnstemp) {
            if(dvtp != 0){
                
                if(this.funClick)
                    elebtn.addEventListener('touchend', this.click.bind(this));
                
                elebtn.addEventListener('touchstart', this.touchstart.bind(this));
                
                elebtn.addEventListener('touchmove', this.touchmove.bind(this));
            }else{
                if(this.funClick)
                    elebtn.addEventListener('click', this.clickFix.bind(this));

                if(this.funOut)
                    elebtn.addEventListener('mouseleave', this.out.bind(this));
                
                if(this.funOver)
                    elebtn.addEventListener('mouseenter', this.over.bind(this));

                if(this.funSwipe){
                    elebtn.addEventListener('mousedown', this.mousedown.bind(this));
                    elebtn.addEventListener('mousemove', this.mousemove.bind(this));

                   // elebtn.addEventListener('mouseup', this.mouseup.bind(this));
                }
                    
            }

            elebtn.classList.add("btnfixpointer");

            elebtn.buttonsstr = _buttons;
    
            this.arrbtns.push(elebtn);
        }

       
    }

    deleteAllBtns(){
        for (let i = 0; i < this.arrbtns.length; i++) {
            const elebtn = this.arrbtns[i];


            if(dvtp != 0){
                elebtn.removeEventListener('touchend', this.click.bind(this));
                elebtn.removeEventListener('touchstart', this.touchstart.bind(this));
                elebtn.removeEventListener('touchmove', this.touchmove.bind(this));

            }else{
                elebtn.removeEventListener('click', this.clickFix.bind(this));
                elebtn.removeEventListener('mouseleave', this.out.bind(this));
                elebtn.removeEventListener('mouseenter', this.over.bind(this));

                if(this.funSwipe){
                    elebtn.removeEventListener('mousedown', this.mousedown.bind(this));
                    elebtn.removeEventListener('mousemove', this.mousemove.bind(this));
                }
            }

        }
    }
}

export default BtnTropical;