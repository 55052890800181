import Menupisco from "./components/menu";
import Coctail from "./section/coctail";
import Contacto from "./section/contacto";
import Contenido from "./section/contenido";
import Galeria from "./section/galeria";
import Home from "./section/home";
import Productos from "./section/productos";
import HTMLtoGL from "./tropical/pixi/HTMLtoGL";
import TropicalPixiBase from "./tropical/pixi/TropicalPixiBase";
import BtnTropical from "./tropical/utils/BtnTropical";
import { getUrlAssets } from "./tropical/utils/Utils";

var picsi;
var isedadok;
var isloadok;
var porloadall;
var conload;
var allok;
function init() {
  picsi = new TropicalPixiBase();

  var arranis = ['especial','reserva','transparente','roble','negra','envejecido', 'cumbre', 'berries' ];

  for (let i = 0; i < arranis.length; i++) {
    const _name = arranis[i];
    
    if(dvtp == 0){
      picsi.app.loader.add(getUrlAssets()+'/img/'+_name+'.json');
      picsi.app.loader.add(getUrlAssets()+'/img/'+_name+'_trans.json');
    }else{
      picsi.app.loader.add(getUrlAssets()+'/img/animob/'+_name+'/'+_name+'-0.png');
      picsi.app.loader.add(getUrlAssets()+'/img/animob/'+_name+'/'+_name+'-1.png');
      picsi.app.loader.add(getUrlAssets()+'/img/animob/'+_name+'/'+_name+'-2.png');
      picsi.app.loader.add(getUrlAssets()+'/img/animob/'+_name+'/'+_name+'-3.png');

      if(_name != 'berries'){
        picsi.app.loader.add(getUrlAssets()+'/img/animob/'+_name+'/'+_name+'-4.png');
        picsi.app.loader.add(getUrlAssets()+'/img/animob/'+_name+'/'+_name+'-5.png');
      }
    }

  }

  isedadok = false;
  isloadok = false;
  porloadall = 0;
  conload = false;
  allok = false;

  picsi.app.loader.load(function(){
    isloadok = true;
    goCheka();
  });

  var contedad = document.querySelector('.contedad');
  var linepor = contedad.querySelector('.cargando .barload .fixline .linepor');

  picsi.app.loader.onProgress.add((e) => {
    ////console.log('onProgress', e)
    porloadall = e.progress;
    linepor.style.width = porloadall+"%";
  });

  // picsi.app.loader.onLoad.add((e) => {
  //   //console.log('onLoad', e)
  // });
  // picsi.app.loader.onComplete.add((e) => {
  //   //console.log('onComplete', e)
  // });

  goedad();
  //showload();
}

function goCheka(){
  if(isloadok && isedadok){

    if(!conload || conload && allok)
      gositio();
  }
}

function goedad(){

  // var contload = document.querySelector('.cargando');
  // gsap.to(contload, {
  //     duration: .5,
  //     css: {
  //         opacity: 0
  //     },
  //     onComplete:function(){
  //       contload.classList.add('oculto');
  //     }
  // });

  var btnterminos = new BtnTropical(function(){
    return false;
  }.bind(this),{
      click:function(e){

        var terminoscont = document.querySelector('.terminoscont');
        terminoscont.classList.remove('oculto');
      }
  });

  var closeterminos = new BtnTropical(function(){
    return false;
  }.bind(this),{
      click:function(e){

        var terminoscont = document.querySelector('.terminoscont');
        terminoscont.classList.add('oculto');
      }
  });
  

  var btnsclose = new BtnTropical(function(){
    return false;
  }.bind(this),{
      click:function(e){

        btnsclose.deleteAllBtns();

        if(!isloadok){
          nextload();
        }else{ 
          isedadok = true; 
          //console.log('altoke casisite');
          casisite();
        }

      },
      over:function(e){
          
      },
      out:function(e){
          
      }
  });


  var contedad = document.querySelector('.contedad');
  var contform = contedad.querySelector('.contform');
  gsap.to(contform, {
      duration: .5,
      css: {
          opacity: 1
      },
      onComplete:function(){
        closeterminos.addBtn('.terminoscont .closeterminos');
        btnterminos.addBtn('.contedad .terminosycondiciones');
        btnsclose.addBtn('.sino .si');
      }
  });

}

function nextload(){
  //console.log('next load');
  var contedad = document.querySelector('.contedad');
  var contform = contedad.querySelector('.contform');
  const childern = contform.childNodes;

  for (let i = 0; i < childern.length; i++) {
    const element = childern[i];
    const ndela = i*.05;

    let _param = {
      duration: .3,
      delay: ndela,
      css: {
          opacity: 0
      }
    };

    if(i >= (childern.length-1)){
      //console.log('finals');
      _param.onComplete = function(){

        isedadok = true; 
        // aqui podemos antes igual chekear de nuevo 

        if(!isloadok){
          //console.log('showload');
          showload();
        }else{
          
          //console.log('whaaat');
          goCheka();
        }
      }
    }

    gsap.to(element, _param);

    
  }
}

function showload(){
  conload = true;
  var contedad = document.querySelector('.contedad');
  var contform = contedad.querySelector('.contform');
  var cargando = contedad.querySelector('.cargando');
  contform.classList.add('oculto');
  cargando.classList.remove('oculto');

  gsap.to(cargando, {
      duration: .1,
      css: {
          opacity: 1
      },
      onComplete:function(){
        //
        allok = true;
        goCheka();
      }
  });
}

function gositio(){

  var contedad = document.querySelector('.contedad');
  var cargando = contedad.querySelector('.cargando');

  gsap.to(cargando, {
      duration: .5,
      css: {
          opacity: 0
      },
      onComplete:function(){
        casisite();
      }
  });
  

}

function casisite(){

  var contedad = document.querySelector('.contedad');
  var contform = contedad.querySelector('.contform');

  gogogo();

  gsap.to(contedad, {
      duration: .2,
      css: {
          opacity: 0
      },
      onComplete:function(){
        contedad.classList.add('oculto');
        
        //gositio();
      }
  });
  
}

var arranis = ['especial','reserva','transparente','roble','negra','envejecido', 'cumbre', 'berries' ];
var idrender = 0;
// Pisco-Especial-Rotacion_00000

function getnum(_id){
  if(_id < 10){
    return '0'+_id;
  }else{
    return _id;
  }
}

function renderYE(idrender, _id, _aniname){

  var anispecial = document.querySelector('.ani'+_id);
  
  anispecial.querySelector('#'+_id+'0').style.opacity = 0;
  anispecial.querySelector('#'+_id+'1').style.opacity = 0;
  anispecial.querySelector('#'+_id+'2').style.opacity = 0;
  anispecial.querySelector('#'+_id+'3').style.opacity = 0;
  anispecial.querySelector('#'+_id+'4').style.opacity = 0;
  anispecial.querySelector('#'+_id+'5').style.opacity = 0;

  if(_id == 'berries'){
    if(idrender >= 0 && idrender <= 15){
      anispecial.querySelector('#'+_id+'0').style.opacity = 1;
      anispecial.querySelector('#'+_id+'0').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 16 && idrender <= 31){
      anispecial.querySelector('#'+_id+'1').style.opacity = 1;
      anispecial.querySelector('#'+_id+'1').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 32 && idrender <= 47){
      anispecial.querySelector('#'+_id+'2').style.opacity = 1;
      anispecial.querySelector('#'+_id+'2').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 48 && idrender <= 49){
      anispecial.querySelector('#'+_id+'3').style.opacity = 1;
      anispecial.querySelector('#'+_id+'3').setAttribute('class',_aniname+getnum(idrender));
    }
  }else{
    if(idrender >= 0 && idrender <= 8){
      anispecial.querySelector('#'+_id+'0').style.opacity = 1;
      anispecial.querySelector('#'+_id+'0').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 9 && idrender <= 17){
      anispecial.querySelector('#'+_id+'1').style.opacity = 1;
      anispecial.querySelector('#'+_id+'1').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 18 && idrender <= 26){
      anispecial.querySelector('#'+_id+'2').style.opacity = 1;
      anispecial.querySelector('#'+_id+'2').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 27 && idrender <= 35){
      anispecial.querySelector('#'+_id+'3').style.opacity = 1;
      anispecial.querySelector('#'+_id+'3').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 36 && idrender <= 44){
      anispecial.querySelector('#'+_id+'4').style.opacity = 1;
      anispecial.querySelector('#'+_id+'4').setAttribute('class',_aniname+getnum(idrender));
    }else if(idrender >= 45 && idrender <= 49){
      anispecial.querySelector('#'+_id+'5').style.opacity = 1;
      anispecial.querySelector('#'+_id+'5').setAttribute('class',_aniname+getnum(idrender));
    }
  }
  
}

function renderBoteCSS(){

  renderYE(idrender, 'especial', 'Pisco-Especial-Rotacion_000');
  renderYE(idrender, 'reserva', 'Pisco-Reserva-Rotacion_000');
  renderYE(idrender, 'transparente', 'Pisco-Transparente-Rotacion_000');
  renderYE(idrender, 'roble', 'Pisco-Roble-Rotacion_000');
  renderYE(idrender, 'negra', 'Rotacion-Etiqueta-Negra_000');
  renderYE(idrender, 'envejecido', 'Pisco-Envejecido-Rotacion_000');
  renderYE(idrender, 'cumbre', 'Pisco-Cumbre-Rotacion');
  renderYE(idrender, 'berries', 'Cherrie-Rotacion');
  
  idrender++;

  if(idrender > 49){
    idrender = 0;
  }
}

function gogogo(){
  
  var htmlToGl = new HTMLtoGL(picsi, .2, function(){
    ////console.log('function');
  });

  var menu = new Menupisco(htmlToGl);
 
  picsi.setAnimate(function(){
    ////console.log('render');
    htmlToGl.render();
    menu.render();
    
  });

  if(dvtp != 0){
    document.querySelector('.aniespecial').classList.remove('oculto');
    document.querySelector('.anireserva').classList.remove('oculto');
    document.querySelector('.anitransparente').classList.remove('oculto');
    document.querySelector('.aniroble').classList.remove('oculto');
    document.querySelector('.aninegra').classList.remove('oculto');
    document.querySelector('.anienvejecido').classList.remove('oculto');
    document.querySelector('.anicumbre').classList.remove('oculto');
    document.querySelector('.aniberries').classList.remove('oculto');

    setInterval(() => {
      renderBoteCSS();
    }, 24);
  }
  

  var home = new Home(picsi, htmlToGl, function(){

    document.body.classList.remove('example');
    htmlToGl.resize();
    producto.handleResize();

    htmlToGl.scrollgo();

  });

  var producto = new Productos(picsi, htmlToGl);

  var coctail = new Coctail(picsi, htmlToGl);
  
  var galeria = new Galeria(picsi, htmlToGl);

  //var contenido = new Contenido(picsi, htmlToGl);

  var contacto = new Contacto();

  setTimeout(function(){
    htmlToGl.resize();
    producto.handleResize();
  }.bind(this),1000);




}

window.onload = function() {
  init();
};