import { getUrlAssets } from '../utils/Utils';
import GlObject from './GlObject';

class PlaneMontain extends GlObject { 
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    this.typemontain = el.getAttribute('data-monta-type');
    this.uIndex = index;

    var fixscro = this.getPosScroll();
    this.lastTopShift = fixscro.speed; 
    
    var urlcover = getUrlAssets()+'/img/bgandes.png'//
    const texture = PIXI.Texture.from(urlcover);
    
    this.mesh = new PIXI.Sprite( texture );
    this.mesh.alpha = .6;

    if( this.typemontain == 'mon_contact'){
      this.mesh.anchor.x = .5;
    }else if(this.typemontain == 'mon_coctel_aba'){
      this.mesh.rotation = 3.15;
      this.mesh.anchor.y = .4;
      this.mesh.anchor.x = .75;
    }else{
      this.mesh.anchor.x = 1;
    }


    this.bggr = new PIXI.Graphics();
    this.bggr.beginFill(0xff00ff);
    this.bggr.drawRect(0, 0, 100, 100);
    this.bggr.endFill();
    this.addChild(this.bggr);

    this.addChild(this.mesh);

    this.mesh.mask = this.bggr;

  }

  goIntro(_delay, _callback){
    //console.log('intro tb');
    // this.setNoiseParam();
    // this.material.uniforms.uActive.value = 1;
    // //this.material.uniforms.uProg = 0.25;
    // gsap.set(this.material.uniforms.uProg, { value: 0.5 });


    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: 0,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 0,
    //   });
  }


  goBye(_delay, _callback){
    // this.material.uniforms.uActive.value = 1;
  
    if(this.interimg)
      clearInterval(this.interimg);

    gsap.to(this, {
      delay: _delay,
      duration: .25,
      alpha: 0,
      ease: 'power.inOut',
      onComplete:function(){

        // if(this && this.material){
        //     this.material.uniforms.uActive.value = 0;
        // }

        if(_callback)
          _callback();
      }.bind(this),
    });
    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: -1,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 1,
    //   });
  }

  resize(){
    super.resize();

    this.resizeFixTexture();
  }

  resizeFixTexture(){
    if(this.bggr){
      var hh = this.bounds.height;
      var ww = this.bounds.width;

      var hhb = this.bounds.height-30;
      var wwb = this.bounds.width-30;

      var vW = 1;
      var vH = 1;
      var vWb = 1;
      var vHb = 1;

      if(ww > hh){
        vH = ( (hh*1)/ww );
        vHb = ( (hhb*1)/(wwb) );
      }else{
        vW = ( (ww*1)/hh );
        vWb = ( (wwb*1)/(hhb) );
      }

      // this.material.uniforms.u_adjust_uv.value = new THREE.Vector2(vW, vH);
      // this.material.uniforms.u_adjust_uv_border.value = new THREE.Vector2(vWb, vHb);

      const vw = window.innerWidth;

      this.bggr.width = ww;
      this.bggr.height = hh;

      var posx = ww;
      //this.mesh.position.y = hh;
      if( this.typemontain == 'mon_contact'){
        posx = 180;
      }else if( this.typemontain == 'mon_prod'){
        posx = vw/1.7;
      }else if( this.typemontain == 'mon_coctel'){
        posx = (vw/2)+(ww/3);

        //this.bggr.position.x = posx-ww;
        this.mesh.position.y = 70;
      }else if( this.typemontain == 'mon_coctel_aba'){
        //posx = 0;
      }

      

      if( this.typemontain == 'mon_contact'){
        //this.bggr.position.x = posx-ww;
        this.mesh.position.y = 70;
        this.mesh.position.x = posx;
      }else if( this.typemontain == 'mon_coctel_aba'){
        this.bggr.position.x = posx-ww;
        this.mesh.position.x = posx-ww;
      }else{
        this.bggr.position.x = posx-ww;
        this.mesh.position.x = posx;
      }

      // this.mesh.width = ww;
      // this.mesh.height = hh;

      // this.mesh.position.x = 20;

    }
  }

  byebye(){
    this.removeChild(this.bggr);
    this.removeChild(this.mesh);

    // this.material.dispose();
    // this.geometry.dispose();
    // this.texture.dispose();

    this.material = undefined;
    this.geometry = undefined;
    this.texture = undefined;
    this.mesh = null;
  }

}

export default PlaneMontain;