

export function getProducts(){
    return [
        {
            link: 'https://andina.micoca-cola.cl/piscos-y-licores/Pisco/Alto%20del%20Carmen?O=OrderByTopSaleDESC&PS=48&map=c,specificationFilter_769,specificationFilter_768',
            //link: 'https://andina.micoca-cola.cl/alto-del-carmen-35-750-ml/p',
            grado: '35',
            title:'ESPECIAL',
            desc:'Un producto aromático, limpio y de carácter moscatel.',
            guarda:'4 meses en barricas de roble americano.',
            tipo:'Moscatel Rosada y Moscatel de Alejandría.',
            notas:'Color amarillo dorado y brillante. En nariz: intensidad alta de moscatel con ligeras notas a vainilla. En boca: dulce, redondo y suave. Persistencia media.',
            marinaje:'Diseñado para Pisco Sour y Piscola.',
            temperatura:'Tº de Servicio: 7ºC a 12ºC'
        },
        {
            link: 'https://andina.micoca-cola.cl/alto-del-carmen-reservado-40-750-ml/p',
            grado: '40',
            title:'RESERVADO',
            desc:'El clásico Pisco Moscatel.',
            guarda:'9 meses en barricas de roble americano.',
            tipo:'Moscatel Rosada y Moscatel de Alejandría.',
            notas:'Color ámbar medio y brillante. Las piernas son finas y delicadas. En nariz: intensidad media de moscatel, con equilibradas notas tostadas destacándose el caramelo y frutos secos. En boca:  dulce, redondo, moscatel, maderas ',
            marinaje:'Un gran producto diseñado para consumo puro, en piscola y alta coctelería.',
            temperatura:'Tº de Servicio: 7ºC a 12ºC'
        },
        {
            link: 'https://andina.micoca-cola.cl/alto-del-carmen-transparente-40-750-ml/p',
            grado: '40',
            title:'RESERVADO transparente',
            desc:'Doble destilado, transparente de carácter moscatel.',
            guarda:'Más de un año en estanques de acero inoxidable.',
            tipo:'Moscatel rosada y Moscatel de Alejandría.',
            notas:'Transparente y brillante. En nariz: intensidad media a moscatel, limpio, notas cítricas y flores de azahar. En boca: dulce, fresco y untuoso. Persistencia media.',
            marinaje:'Perfectamente diseñado para un Pisco Sour o coctelería premium.',
            temperatura:'Tº de Servicio: -10ºC a 10ºC'
        },
        {
            link: 'https://andina.micoca-cola.cl/alto-del-carmen-roble-del-sur-35-750-ml/p',
            grado: '35',
            title:'roble del sur',
            desc:'Pisco con notas amaderadas y dulces de cada barrica de roble americano.',
            guarda:'5 meses en barricas de roble americano.',
            tipo:'Moscatel de Austria',
            notas:'Color ámbar medio y brillante. En nariz: intensidad media del roble con equilibradas notas tostadas, destacándose el caramelo y frutos secos. En boca: dulce, redondo, maderas finas tostadas. Persistencia media.',
            marinaje:'Ideal acompañar con chocolate. Diseñado para su consumo puro, con hielo, con bebidas cola, Piscola premium y coctelería de Lujo.',
            temperatura:'Tº de Servicio: 7ºC a 12ºC'
        },
        {
            link: 'https://andina.micoca-cola.cl/alto-del-carmen-etiqueta-negra-750-ml/p',
            grado: '40',
            title:'etiqueta negra',
            desc:'Un pisco evolucionado y elegante.',
            guarda:'2 años o más en barricas de roble americano.',
            tipo:'Moscatel rosada y Moscatel de Alejandría.',
            notas:'Color ámbar intenso, limpio y brillante. De piernas finas y persistentes. En nariz: intensidad alta de moscatel evolucionado. Con gran nota a ciruelas en almíbar, pasas y frutos secos. En boca: dulce, suave, redondo y evolucionado. De gran persistencia.',
            marinaje:'Un producto diseñado como bajativo y utilizado en alta coctelería.',
            temperatura:'Tº de Servicio: 10ºC a 16ºC'
        },
        {
            link: 'https://andina.micoca-cola.cl/alto-del-carmen-barricas-de-seleccion-40-750-ml/p',
            grado: '40',
            title:'barricas de selecciÓn',
            desc:'Una selección de nuestras mejores barricas de roble americano.',
            guarda:'7 años en barricas de roble americano.',
            tipo:'Moscatel rosada y Moscatel de Alejandría.',
            notas:'Color ámbar profundo, brillante y limpio. En nariz: notas a ciruela, pasa y especies. En boca: es suave, complejo y evolucionado. De larga persistencia.',
            marinaje:'Es un producto diseñado para coctelería premium, en especial con licores de naranja. Ideal para acompañar carnes a la parrilla.',
            temperatura:'Tº de Servicio: 14ºC a 18ºC'
        },
        {
            link: 'https://andina.micoca-cola.cl/alto-del-carmen-cumbre-40-750-ml/p',
            grado: '40',
            title:'cumbre, the essence of muscat',
            desc:'Una mezcla de tres tipos de destilación. Un pisco con corazón que otorga carácter. Una doble destilación que otorga elegancia y una triple que le da una pureza única.',
            guarda:'3 años en estanques de acero inoxidable.',
            tipo:'Moscatel rosada y Moscatel de Alejandría.',
            notas:'Transparente, brillante y limpio. Con largas, delicadas y persistentes piernas. En nariz, intensidad alta de un Moscatel maduro, elegante, limpio y de inigualable carácter. En boca, muy suave, dulce, gran carácter, elegante, fresco y complejo. Persistencia muy alta.',
            marinaje:'Maridaje premium con mariscos, pulpo al olivo y comidas más cítricas. Diseñado para su consumo puro, con hielo y en coctelería de lujo.',
            temperatura:'Tº de Servicio: -10ºC a 10ºC'
        },
        {
            link: 'https://andina.micoca-cola.cl/alto-del-carmen-high-berries-40-750-ml/p',
            grado: '40',
            title:'HIGH BERRIES',
            desc:'Licor de Pisco en base a Alto del Carmen transparente doble destilado y un licor de frutos del bosque. De carácter frutal y cítrico, selección de la mejor uva Moscatel.',
            guarda:'5 meses de reposo en vasijas de acero inoxidable.',
            tipo:'Moscatel Rosada y Moscatel de Alejandría.',
            notas:'Color rojo carmesí, brillante y límpido. Con largas y persistentes piernas.</br>En nariz: aroma a frutos rojos y arándano, con notas cítricas y a especies.</br>En boca: complejo, frutal y especies, con buena estructura y gran carácter. Con una buena persistencia. Una leve y elegante amargor final.',
            marinaje:'Diseñado para la coctelería como un sour de Berries y en especial para tomar como un Spritz, con mucho hielo, espumante brut, bebidas blancas como Ginger o tónica y una rodaja de naranja para entregarle el cítrico a la preparación.',
            temperatura:'Tº de Servicio: -10°C a 10°C'
        }
    ];
}
export function openURL(a, b) {
    if (!b) {
        b = "_blank"
    }
    window.open(a, b)
};

export function getUrlAssets(){
	return strassets;
}

export function replaceAll(target, search, replacement) {

    return target.split(search).join(replacement);
};

export function cloneArray(_arr){
	return _arr.slice(0);
} 

export function arrayUnique(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export function utilDynamicSort(property){
	let sortOrder = 1;
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1); // eslint-disable-line no-param-reassign
	}
	/* eslint-disable max-len */
	return function(a, b) {
		const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0; // eslint-disable-line no-nested-ternary
		return result * sortOrder;
	};
	/* eslint-enable max-len */
}

export function reverseArr(input) {
    var ret = new Array;
    for(var i = input.length-1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
}

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}