import BtnTropical from "../tropical/utils/BtnTropical";

class Contenido {

    constructor(_pixijsManager, _htmlToGl){

        var _this = this;
        this.pixijsManager = _pixijsManager;
        this.htmlToGl = _htmlToGl;

        const strfindbtn = '.contenidocont [data-plane-id]';

        this.bloqueobtns = false;

        var btns = new BtnTropical(function(){
            return this.bloqueobtns;
        }.bind(this),{
            click:function(e){
                const ele = e.target;
                _this.goOneProd(ele);
            },
            over:function(e){
                const ele = e.target;
                const idele = ele.getAttribute('data-plane-id');
                var planetemp = _this.htmlToGl.getPlaneTB(idele);
                planetemp.mouseEnter();
            },
            out:function(e){
                const ele = e.target;
                const idele = ele.getAttribute('data-plane-id');
                var planetemp = _this.htmlToGl.getPlaneTB(idele);
                planetemp.mouseLeave();
            }
        });

        btns.addBtn(strfindbtn);

        // const btnsprod = document.querySelectorAll(strfindbtn);
        

        // for (let i = 0; i < btnsprod.length; i++) {
        //     const element = btnsprod[i];
        //     var idtb = element.getAttribute('data-plane-id');

        //     if(idtb){
        //         console.log(idtb);
        //     }
        // }

        window.addEventListener('resize', this.handleResize.bind(this), false);
    }

    handleResize(){
        
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        
        if(this.bgmodal){
            this.bgmodal.width = vw;
            this.bgmodal.height = vh;

        }
    }

    scaleimg(){
        const vh = window.innerHeight;
        //440x784
        return (vh*1)/950;
    }

    goOneProd(element){
        var _this = this;
        const id = element.getAttribute('data-plane-id');
        const idproduct = element.getAttribute('data-contenido-id');
        var planetb = this.htmlToGl.getPlaneTB(id);

        var tbpos = planetb.position;

        this.modalfix = new PIXI.Container();
        //this.modalfix.addChild(planetb.fixtb);

        this.bgmodal = new PIXI.Graphics();
        this.bgmodal.beginFill(planetb._color);
        this.bgmodal.drawRect(0, 0, 100, 100);
        this.modalfix.position.x = tbpos.x;
        this.modalfix.position.y = tbpos.y;
        //this.pixijsManager.app.stage.addChild(this.group);
        //this.htmlToGl.group
        this.bgmodal.position.x = tbpos.x;
        this.bgmodal.position.y = tbpos.y;
        this.bgmodal.width = planetb.bggr.width;
        this.bgmodal.height = planetb.bggr.height;

        this.htmlToGl.modal.addChild(this.bgmodal);
       // this.htmlToGl.modal.addChild(this.modalfix);

        
        const vw = window.innerWidth;
        const vh = window.innerHeight;

        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var modalprod = document.querySelector('.modal_contenido');
        var continfo =  modalprod.querySelector('.continfo');
        var closemodal =  document.querySelector('.modal_contenido .closemodal');
        

        this.contbggrplo = new PIXI.Container();
        this.bggrplo = new PIXI.Graphics();
        //this.bggrplo.alpha = 0;
        this.bggrplo.position.x = vw;
        
        this.contbggrplo.addChild(this.bggrplo);
        // vvvvv

        const frames = [];

        for (let i = 0; i < 30; i++) {
            const val = i < 10 ? `0${i}` : i;

            frames.push(PIXI.Texture.from(`rollSequence00${val}.png`));
        }


        //

        this.htmlToGl.modal.addChild(this.contbggrplo);

        //

        var cninfo = continfo.childNodes;

        for (let i = 0; i < cninfo.length; i++) {
            const element = cninfo[i];
            
            gsap.set(element, {
                css: {
                    transform:'translate3D(100px,0,0)',
                    opacity: 0
                },
            });
        }


        modalprod.classList.remove('oculto');
        document.body.classList.add('example');


        var porceh = this.scaleimg();

        gsap.set(closemodal, {css:{opacity:0} });
        gsap.to(closemodal, {delay: .5, duration: .8, css:{opacity:1} });

        gsap.to(contsite, {duration: .4, css:{opacity:0} });

        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,-100%,0)'} });
       
        gsap.to(contlogotop, {duration: .4, delay: .1, css:{opacity:0} });
        
        gsap.to(this.bggrplo, {
                // duration: 1,
                delay: .3,
                alpha: 1});
        gsap.to(this.bggrplo.position, {
                // duration: 1,
                delay: .3,
                x: 0});


        for (let i = 0; i < cninfo.length; i++) {
            const element = cninfo[i];
            
            var ndela = .4+(.04*i);

            gsap.to(element, {
                delay: ndela,
                css: {
                    transform:'translate3D(0,0,0)',
                    opacity: 1
                },
            });
        }


        gsap.to(this.bgmodal, {
                // duration: 1,
                width: vw,
                height: vh,
                ease: 'power.inOut',
            });

        gsap.to(this.bgmodal.position, {
                // duration: 1,
                x: 0,
                y: 0,
                ease: 'power.inOut',
            });

        setTimeout(function(){
            
        }.bind(this), 1000);
        

        var btnsclose = new BtnTropical(function(){
            return this.bloqueobtns;
        }.bind(this),{
            click:function(e){
                    // planetb.addChild(planetb.fixtb);
               _this.byebyeModal();
            },
            over:function(e){
                
            },
            out:function(e){
                
            }
        });

        btnsclose.addBtn('.modal_contenido .closemodal');

    }

    byebyeModal(){
        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var modalprod = document.querySelector('.modal_contenido');
        var continfo =  modalprod.querySelector('.modal_contenido .continfo');
        var closemodal =  document.querySelector('.modal_contenido .closemodal');

        var cninfo = continfo.childNodes;

        const vw = window.innerWidth;
        const vh = window.innerHeight;

        gsap.to(closemodal, {duration: .4, css:{opacity:0} });

        for (let i = 0; i < cninfo.length; i++) {
            const element = cninfo[i];
            
            var ndela = (.02*i);

            var _paramt = {
                delay: ndela,
                duration: .5,
                css: {
                    transform:'translate3D(200px,0,0)',
                    opacity: 0
                },
            };

            if(i >= (cninfo.length-1)){
                _paramt.onComplete = function(){

                    this.htmlToGl.modal.removeChild(this.contbggrplo);
                    this.htmlToGl.modal.removeChild(this.bgmodal);
                    this.bgmodal = null;
                    this.bggrplo = null;

                    modalprod.classList.add('oculto');
                }.bind(this);
            }
            gsap.to(element, _paramt);
        }

        document.body.classList.remove('example');
        window.scrollTo(0, this.htmlToGl.scrollmov.target);


        gsap.to(this.bgmodal, {
            duration: 1,
            delay: .2,
            alpha: 0});

        gsap.to(this.bggrplo.position, {
            duration: 1,
            delay: .2,
            x: (vw)});

        //this.htmlToGl.modal.removeChild(this.modalfix);
        gsap.to(contsite, { delay: .3, css:{opacity:1} });
        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,0,0)'} });
        gsap.to(contlogotop, {duration: .4, delay: .6, css:{opacity:1}, onComplete:function(){
            
            

        }.bind(this) });

        
    }
}

export default Contenido;