export function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getDistance(x1, x2, y1, y2){
  var a = x1 - x2;
  var b = y1 - y2;

  var c = Math.sqrt( a*a + b*b );

  return c;
}

export function isOdd(valor){
  return (valor%2) ? false : true;
}

export function lerp(a, b, n) {
  return a * (1 - n) + b * n;
}