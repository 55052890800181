
import GlObject from './GlObject';
import { getUrlAssets } from '../utils/Utils';

class PlaneTriangulo extends GlObject { 
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    this.uIndex = index;

    var fixscro = this.getPosScroll();
    this.lastTopShift = fixscro.speed; 

    this.imgdata= {w: 300, h: 300};

    this.bggr = new PIXI.Graphics();
    
    var tritype = el.getAttribute('data-tri-type');

    this.bggr.beginFill(0x000000);

    if(tritype == 'rb'){
      this.bggr.moveTo(100, 0);
      this.bggr.lineTo(0, 100);
      this.bggr.lineTo(100, 100);
    }else if(tritype == 'lb'){
      this.bggr.moveTo(0, 0);
      this.bggr.lineTo(0, 100);
      this.bggr.lineTo(100, 100);
    }else if(tritype == 'rt'){
      this.bggr.moveTo(0, 0);
      this.bggr.lineTo(100, 100);
      this.bggr.lineTo(100, 0);
    }else{
      this.bggr.moveTo(0, 0);
      this.bggr.lineTo(0, 100);
      this.bggr.lineTo(100, 0);

    }

    // Rectangle
    this.bggr.closePath();
    
    this.bggr.endFill();

    this.typemonta = el.getAttribute('data-monta-type');
    
    if(this.typemonta == 'mon_prod_tb'){

      this.bggrand = new PIXI.Graphics();
      this.bggrand.beginFill(0xff00ff);
      this.bggrand.drawRect(0, 0, 100, 100);
      this.bggrand.endFill();
      this.addChild(this.bggrand);

      var urlcover = getUrlAssets()+'/img/bgandes.png';//
      const texture = PIXI.Texture.from(urlcover);
      
      this.mesh = new PIXI.Sprite( texture );
      this.mesh.alpha = .6;
      this.mesh.anchor.y = 1;


      //   this.bggrplo = new PIXI.Graphics();
      //   this.bggrplo.beginFill(0xdddddd);
      //   this.bggrplo.drawRect(0, 0, 100, 100);
      //   this.bggrplo.endFill();
      //   this.bggrplo.alpha = .6;
      //  // this.addChild(this.bggrplo);
        
      this.addChild(this.mesh);
      this.addChild(this.bggrand);

      this.mesh.mask = this.bggrand;


      // console.log('jkasjdhasidbaijs')
      // this.bgcerritopaabajo = new PIXI.Graphics();
      // this.bgcerritopaabajo.beginFill(0xff0000);
      // this.bgcerritopaabajo.drawRect(0, 0, 100, 100);
      // this.bgcerritopaabajo.endFill();
      // this.addChild(this.bgcerritopaabajo);
    }

    this.addChild(this.bggr);


    this.typetb = el.getAttribute('data-tb-type');

    if(this.typetb == 'galeria_tb_triangulo'){
      this.bggr.position.y = 10;
    }

    if(this.bgcerritopaabajo)
      this.bggr.mask = this.bgcerritopaabajo;

    this.imgchange = 0;
  }


  

  goIntro(_delay, _callback){
    //console.log('intro tb');
    // this.setNoiseParam();
    // this.material.uniforms.uActive.value = 1;
    // //this.material.uniforms.uProg = 0.25;
    // gsap.set(this.material.uniforms.uProg, { value: 0.5 });


    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: 0,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 0,
    //   });
  }


  goBye(_delay, _callback){
    // this.material.uniforms.uActive.value = 1;
  
    if(this.interimg)
      clearInterval(this.interimg);

    gsap.to(this, {
      delay: _delay,
      duration: .25,
      alpha: 0,
      ease: 'power.inOut',
      onComplete:function(){

        // if(this && this.material){
        //     this.material.uniforms.uActive.value = 0;
        // }

        if(_callback)
          _callback();
      }.bind(this),
    });
    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: -1,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 1,
    //   });
  }

  resize(){
    super.resize();


    // if(this.bggrplo){

    //   const vw = window.innerWidth;
    //   const vh = window.innerHeight;

    //   if(vw < 950){
    //     this.bggrplo.alpha = 0;
    //   }else{

    //     this.bggrplo.alpha = .6;
    //   }
    // }
    this.resizeFixTexture();
  }

  resizeFixTexture(){
    if(this.bggr){
      var hh = this.bounds.height;
      var ww = this.bounds.width;

      // this.mesh.position.x = 20;
      // this.mesh.position.y = 20;

      if(this.typemonta == 'mon_prod_tb'){

        //360x370
        
        //1280 x 589
        var porceH = (hh*1)/370;
        var porceW = (ww*1)/360;

        this.mesh.scale.set(1, porceH);
        this.mesh.position.y = hh-370;
        this.mesh.position.x = -520;
        this.mesh.scale.y = -1;


        // this.bggrplo.width = ww;
        // this.bggrplo.height = hh-370;

        this.bggrand.width = ww;
        this.bggrand.height = hh+370;


        if(this.bgcerritopaabajo){
          this.bgcerritopaabajo.width = ww;
          this.bgcerritopaabajo.height = hh;

        }
      }

      // this.material.uniforms.u_adjust_uv.value = new THREE.Vector2(vW, vH);
      // this.material.uniforms.u_adjust_uv_border.value = new THREE.Vector2(vWb, vHb);



      this.bggr.width = ww;
      this.bggr.height = hh;

    }
  }

  byebye(){
    this.removeChild(this.bggr);
    this.removeChild(this.mesh);

    // this.material.dispose();
    // this.geometry.dispose();
    // this.texture.dispose();

    this.material = undefined;
    this.geometry = undefined;
    this.texture = undefined;
    this.mesh = null;
  }

}

export default PlaneTriangulo;