/***
 * version  0.1;
 * para el pixi 5.4.3
***/


class TropicalPixiBase {
    constructor(_param){
        this.version = '5.0';
        
        this.app = null;

        this.init();
    }
    
	init() {
        this.app = new PIXI.Application({backgroundColor : 0xFFFFFF});
        var bgcanvas = document.querySelector( '.bgcanvas' );
        bgcanvas.appendChild(this.app.view);

        this.handleResize();

        window.addEventListener('resize', this.handleResize.bind(this), false);

    }

    setAnimate(_functionback){
        this.functionback = _functionback;

        this.initAnimate();
    }

    initAnimate(){
        if(this.functionback)
            this.app.ticker.add(this.functionback, this);
    }
    
    stopAnimate(){
        this.app.ticker.remove(this.functionback, this);
        this.functionback = null;
    }
    
    stage(){
        return this.app.stage;
    }
    
    handleResize(){
        var vw = window.innerWidth;
        var vh = window.innerHeight;

        if(this.app){
            this.app.renderer.resize(vw,vh);
        }
    }

}
export default TropicalPixiBase;