import GlObject from './GlObject';

class PlaneIMG extends GlObject { 
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    this.uIndex = index;

    var fixscro = this.getPosScroll();
    this.lastTopShift = fixscro.speed; 

    // var urlcover = el.getAttribute('data-image0');
    // var urlcover1 = el.getAttribute('data-image1');
    // var urlcover2 = el.getAttribute('data-image2');

    // var urlassets = 'https://jotamayuscula.casaparlante.com';

    // this.arrimages = [];
    // this.arrimages[0] = PIXI.Texture.from(urlassets+urlcover);

    // if(urlcover1 && urlcover1 != ''){
    //   this.arrimages[1] = PIXI.Texture.from(urlassets+urlcover1);
    // }


    // if(urlcover2 && urlcover2 != ''){
    //   this.arrimages[2] = PIXI.Texture.from(urlassets+urlcover2);
    // }

   this.imgdata= {w: 300, h: 300};

  //  this.geometry = new PIXI.Geometry()
  //     .addAttribute('aVertexPosition', // the attribute name
  //         [0, 0, // x, y
  //             this.imgdata.w, 0, // x, y
  //             this.imgdata.w, this.imgdata.h,
  //             0, this.imgdata.h], // x, y
  //         2) // the size of the attribute
  //     .addAttribute('aUvs', // the attribute name
  //         [0, 0, // u, v
  //             1, 0, // u, v
  //             1, 1,
  //             0, 1], // u, v
  //         2) // the size of the attribute
  //     .addIndex([0, 1, 2, 0, 2, 3])
  //     .interleave();

  //   this.shader = PIXI.Shader.from( Zero2.vertexShader, Zero2.fragmentShader,
  //   {
  //     uSampler2: PIXI.Texture.from(urlimg),
  //   });

    //this.mesh = new PIXI.Sprite( this.arrimages[0] );

    //this.mesh = new PIXI.Mesh(this.geometry, this.shader);

    this.bggr = new PIXI.Graphics();

    // Rectangle
    this.bggr.beginFill(0xfff000);
    this.bggr.drawRect(0, 0, 100, 100);
    this.bggr.endFill();

    this.bggr.tint = 0xCB1F7C;

    this.addChild(this.bggr);
    //this.addChild(this.mesh);
    

    this.imgchange = 0;

    // if(this.arrimages.length > 0){
    //   this.interimg = setInterval(() => {
    //     this.mesh.texture = this.arrimages[this.imgchange];
    //     this.imgchange++;

    //     if(this.imgchange >= this.arrimages.length){
    //       this.imgchange = 0;
    //     }
    //   }, 500);
    // }
  }

  goIntro(_delay, _callback){
    //console.log('intro tb');
    // this.setNoiseParam();
    // this.material.uniforms.uActive.value = 1;
    // //this.material.uniforms.uProg = 0.25;
    // gsap.set(this.material.uniforms.uProg, { value: 0.5 });


    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: 0,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 0,
    //   });
  }


  goBye(_delay, _callback){
    // this.material.uniforms.uActive.value = 1;
  
    if(this.interimg)
      clearInterval(this.interimg);

    gsap.to(this, {
      delay: _delay,
      duration: .25,
      alpha: 0,
      ease: 'power.inOut',
      onComplete:function(){

        // if(this && this.material){
        //     this.material.uniforms.uActive.value = 0;
        // }

        if(_callback)
          _callback();
      }.bind(this),
    });
    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: -1,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 1,
    //   });
  }

  setNoiseParam(){
    // this.material.uniforms.uNoiseFreq.value = randomRange(0.2, 2.10);
    // this.material.uniforms.uNoiseAmp.value = randomRange(0.2, 3.12);

    // if(this.uIndex == 1){
    //   console.log(this.material.uniforms.uNoiseFreq.value, this.material.uniforms.uNoiseAmp.value);
    // }

  }

  setScaleBG(){
    //console.log('setScaleBG');
    // var viewportHeight = window.innerHeight;
    // var fixscro = this.getPosScroll();
    // var posy = this.bounds.top - fixscro.y;
    // var vph2 = (viewportHeight/2);
    // if( posy > vph2){
    //   var _posy = posy-vph2;
    //   var porceny = (_posy*0.3)/vph2;

    //   var lerpscale = lerp(this.material.uniforms.scale.value, porceny, 0.1);
  
    //   if( this.isActiveScreen() ){
    //     if(lerpscale < 1 && lerpscale > 0){
    //       this.material.uniforms.scale.value = lerpscale;
    //     }
    //   }
    // }

    //this.material.uniforms.scale.value = 0.4;
  }

  setShift(){
    //console.log('setShift');
    // var fixscro = this.getPosScroll();
    // var lerpspeed = lerp(this.material.uniforms.shift.value, (fixscro.speed - this.lastTopShift) / 150, 0.1);
		// var muerto = Math.abs(lerpspeed);

    // var maxim = 1;
		// if(muerto > maxim)
		// 	muerto = maxim;

    // var _speed = muerto;

		// if(lerpspeed < 0){
		// 	_speed = -muerto;
    // }
    
    // if( this.isActiveScreen() ){
    //   this.material.uniforms.shift.value = _speed;
    // }

    // this.lastTopShift = fixscro.speed;
  }

  resize(){
    super.resize();

    this.resizeFixTexture();
  }

  resizeFixTexture(){
    if(this.bggr){
      var hh = this.bounds.height;
      var ww = this.bounds.width;

      var hhb = this.bounds.height-30;
      var wwb = this.bounds.width-30;

      var vW = 1;
      var vH = 1;
      var vWb = 1;
      var vHb = 1;

      if(ww > hh){
        vH = ( (hh*1)/ww );
        vHb = ( (hhb*1)/(wwb) );
      }else{
        vW = ( (ww*1)/hh );
        vWb = ( (wwb*1)/(hhb) );
      }

      // this.material.uniforms.u_adjust_uv.value = new THREE.Vector2(vW, vH);
      // this.material.uniforms.u_adjust_uv_border.value = new THREE.Vector2(vWb, vHb);


      this.bggr.width = ww;
      this.bggr.height = hh;

      // this.mesh.width = ww-40;
      // this.mesh.height = ww-40;

      // this.mesh.position.x = 20;
      // this.mesh.position.y = 20;

    }
  }

  byebye(){
    this.removeChild(this.bggr);
    this.removeChild(this.mesh);

    // this.material.dispose();
    // this.geometry.dispose();
    // this.texture.dispose();

    this.material = undefined;
    this.geometry = undefined;
    this.texture = undefined;
    this.mesh = null;
  }

}

export default PlaneIMG;