import { getUrlAssets } from '../utils/Utils';
import GlObject from './GlObject';

class PlaneTBCoctail extends GlObject { 
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    this.uIndex = index;
    this.typeplane = 'tbcoctail';

    var fixscro = this.getPosScroll();
    this.lastTopShift = fixscro.speed; 

    this.imgdata= {w: 300, h: 300};

    var idcoctail = el.getAttribute('data-coctail-id');

    var urlcover = getUrlAssets()+'/img/coctel'+idcoctail+'.jpg';

    var _this = this;
    const loader = new PIXI.Loader();

    const loadimgid = 'img_coc'+this.uIndex;

    this.bgcocacont = new PIXI.Container();

    this.sizeimgye = null;
    loader.add(loadimgid, urlcover);
    loader.load((loader, resources) => {
      
      _this.sizeimgye = resources[loadimgid].texture.orig;

      _this.bgcoca = new PIXI.Sprite( resources[loadimgid].texture );
      _this.bgcoca.anchor.set(0.5);
      _this.bgcocacont.addChild(_this.bgcoca);

      _this.resizeScaleImg();
    });
    
    
    this.bggr = new PIXI.Graphics();
    
    var tbtype = el.getAttribute('data-tb-color');
    this._color = '0x'+tbtype;

    this.bggr.beginFill(this._color);
    this.bggr.drawRect(0, 0, 100, 100);
    
    this.bggr.endFill();

    this.fixtb = new PIXI.Container();
    this.addChild(this.fixtb);

    this.fixtb.addChild(this.bggr);

    this.fixtb.addChild(this.bgcocacont);


    this.maskbg = new PIXI.Graphics();

    this.maskbg.beginFill(0xff0000);
    this.maskbg.drawRect(0, 0, 100, 100);
    this.maskbg.endFill();
    this.fixtb.addChild(this.maskbg);

    this.bgcocacont.mask = this.maskbg;

    this.resizeFixTexture();
  }

  resizeScaleImg(){
    var porcebgcoc = this.scaleimg();
    this.bgcocacont.scale.set(porcebgcoc);
  }

  goIntro(_delay, _callback){
    
  }


  goBye(_delay, _callback){
    // this.material.uniforms.uActive.value = 1;
  
    if(this.interimg)
      clearInterval(this.interimg);

    gsap.to(this, {
      delay: _delay,
      duration: .25,
      alpha: 0,
      ease: 'power.inOut',
      onComplete:function(){

        // if(this && this.material){
        //     this.material.uniforms.uActive.value = 0;
        // }

        if(_callback)
          _callback();
      }.bind(this),
    });
  }

  mouseEnter() {
   gsap.to(this.bgcoca.scale, {
        // duration: 1,
        x: 1.2, y: 1.2,ease: 'power.inOut'});
  //  this.animbotella.loop = true;
  //  this.animbotella.play();
    // if(this.material){
    //   this.setNoiseParam();

    //   this.material.uniforms.uActive.value = 1;
    //   gsap.to(this.material.uniforms.uProg, {
    //     // duration: 1,
    //     value: 0.25,
    //     ease: 'power.inOut',
    //   });
    // }
   
  }

  mouseLeave() {
    gsap.to(this.bgcoca.scale, {
         // duration: 1,
         x: 1, y: 1,ease: 'power.inOut'});
    //console.log('out');
    // this.animbotella.loop = false;
    // this.animbotella.play();
    // if(this.material){
    //   this.material.uniforms.uActive.value = 0;
    //   gsap.to(this.material.uniforms.uProg, {
    //       // duration: 1,
    //       value: 0,
    //       ease: 'power.inOut',
    //     });
    // }
  }

  resize(){
    super.resize();

    this.resizeFixTexture();
  }

  resizeFixTexture(){
    if(this.bggr){
      var hh = this.bounds.height;
      var ww = this.bounds.width;

      this.bggr.width = Math.ceil(ww);
      this.bggr.height = hh;

      this.maskbg.width = Math.ceil(ww)+10;
      this.maskbg.height = hh;
      
      if(this.bgcocacont){
        this.bgcocacont.x = Math.ceil(ww/2);
        this.bgcocacont.y = (hh/2);

        this.resizeScaleImg();
    
      }

    }
  }

  scaleimg(){
    var hhb = this.bounds.height;
    var wwb = this.bounds.width;

    if(this.sizeimgye != null){
      var imgh = this.sizeimgye.height;
      var imgw = this.sizeimgye.width;

      var scalere = (hhb*1)/imgh;

      var fixw = (imgw*scalere)/1;

      if(fixw < wwb){
        scalere = (wwb*1)/imgw;
      }

      return scalere;

    }else{
      return (hhb*1)/898;
    }
  }

  byebye(){
    this.removeChild(this.bggr);
    this.removeChild(this.mesh);

    // this.material.dispose();
    // this.geometry.dispose();
    // this.texture.dispose();

    this.material = undefined;
    this.geometry = undefined;
    this.texture = undefined;
    this.mesh = null;
  }

}

export default PlaneTBCoctail;