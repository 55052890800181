import { type } from "jquery";
import BtnTropical from "../tropical/utils/BtnTropical";
import { getProducts, getUrlAssets } from "../tropical/utils/Utils";

class Productos {

    constructor(_pixijsManager, _htmlToGl){

        var _this = this;
        this.pixijsManager = _pixijsManager;
        this.htmlToGl = _htmlToGl;

        const strfindbtn = '.productoscont [data-plane-id]';

        this.bloqueobtns = false;

        var btns;
        
        if(dvtp == 0){
            btns = new BtnTropical(function(){
                return this.bloqueobtns;
            }.bind(this),{
                click:function(e){
                    const ele = e.target;

                    var id = ele.getAttribute('data-plane-id');
                    var idproduct = ele.getAttribute('data-product-id');
                    var colorete = ele.getAttribute('data-tb-color');

                    _this.goOneProd(id, idproduct, colorete);
                },
                over:function(e){
                    const ele = e.target;
                    const idele = ele.getAttribute('data-plane-id');
                    var planetemp = _this.htmlToGl.getPlaneTB(idele);
                    planetemp.mouseEnter();
                },
                out:function(e){
                    const ele = e.target;
                    const idele = ele.getAttribute('data-plane-id');
                    var planetemp = _this.htmlToGl.getPlaneTB(idele);
                    planetemp.mouseLeave();
                },
                swipe:function(e){
                    if(e == 'left' || e == 'right'){
                        console.log('swipe', e);
                        _this.goSwip(e);
                    }
                }
            });
        } else {
            btns = new BtnTropical(function(){
                return this.bloqueobtns;
            }.bind(this),{
                click:function(e){
                    const ele = e.target;
                    const id = ele.getAttribute('data-plane-id');
                    const idproduct = ele.getAttribute('data-product-id');
                    const coloreteotro = ele.getAttribute('data-tb-color');
                    _this.goOneProd(id, idproduct, coloreteotro);
                    
                }
            });
        }

        this.confignextprev();


        btns.addBtn(strfindbtn);

        window.addEventListener('resize', this.handleResize.bind(this), false);

    
        this.typeview = true;

        this.handleResize();
        
    }


    getidplane(_idproduct){
        const arrchild = document.querySelectorAll('.fixzonewip .productoscont .itemproducto');

        //ele.getAttribute('data-plane-id');
        var idreturn = null;
        for (let i = 0; i < arrchild.length; i++) {
            const element = arrchild[i];
            var idprod = element.getAttribute('data-product-id');

            if(_idproduct == idprod){
                idreturn = element;
                break;
            }
        }

        return idreturn;
    }

    confignextprev(){

        var _this = this;

        var btns = new BtnTropical(function(){
            return false;
        }.bind(this),{
            click:function(e){
                const ele = e.target;
                const dataside = ele.getAttribute('data-side');
                console.log(dataside, _this.idselecto)

                var idproduct = _this.idselecto+1;
                var nle = Number(_this.yeye.length);

                if(dataside == 'left'){
                    idproduct = _this.idselecto-1;

                    if( idproduct < 0 ){
                        idproduct = nle-1;
                    }

                }else{

                    if( idproduct >= (nle) ){
                        idproduct = 0;
                    }
                }

                const eleidprod = _this.getidplane(idproduct);

                const id = eleidprod.getAttribute('data-plane-id')
                const colorete = eleidprod.getAttribute('data-tb-color');

                _this.goNP(id, idproduct, colorete);
            }
        });

        btns.addBtn('.fixmodalprod .btnsnp .btn');
    }

    goNP(id, idproduct, color){
        var _this = this;


        var modalprod = document.querySelector('.modal_prod');
        var continfo =  modalprod.querySelector('.continfo .continfofix');
        var cninfo = continfo.childNodes;
        var lastdelay = 0;

        for (let i = 0; i < cninfo.length; i++) {
            const element = cninfo[i];
            
            var ndela = .4+(.04*i);
            lastdelay = ndela;

            gsap.to(element, {
                delay: ndela,
                css: {
                    opacity: 0
                },
            });
        }

        lastdelay = lastdelay+.1;

        gsap.to(this.bgnew, {
            // duration: 1,
            delay: lastdelay,
            alpha: 0,
            onComplete:function(){
                console.log('yeah final')
                _this.goOneProd(id, idproduct, color);
            }    
        });

    }

    goSwip(e){

        const vw = window.innerWidth;
        var arrlist = document.querySelectorAll('#sec_productos #zonaswip .itemproducto');
        var tempid = this.nidcurrent;
        if(e == 'left'){
            tempid--;
            if(tempid < 0)
                tempid = 0;
        }else{
            tempid++;
            if(tempid >= arrlist.length)
                tempid = arrlist.length;
        }

        this.nidcurrent = tempid;
      //  document.querySelector('.itemproducto').style.left = '-'+(tempid*vw)+'px';

      this.htmlToGl.renderxtra = function(){

      }

        var arrlista = document.querySelectorAll('.itemproducto');

        for (let i = 0; i < arrlista.length; i++) {
            const element = arrlista[i];
           // document.querySelector('.itemproducto').style.left = '-'+(tempid*vw)+'px';

            gsap.to(element, {
                css: {
                    transform:'translate3D(-'+(this.nidcurrent*vw)+'px,0,0)'
                },
            });
        }
        var zonaswip = document.querySelector('#sec_productos #zonaswip');

        
        //document.querySelector('.itemproducto').setAttribute('style', 'left:-'+(tempid*vw)+'px');
    }

    ordenTbs(){
        const vw = window.innerWidth;
        var arrlist = document.querySelectorAll('#sec_productos #zonaswip .itemproducto');
        for (let i = 0; i < arrlist.length; i++) {
            var wid = vw*i;
            const element = arrlist[i];
           // element.setAttribute('style', 'left:'+wid+'px');
           gsap.killTweensOf(element);
           gsap.set(element, {
            css: {
                transform:'translate3D(-'+(this.nidcurrent*vw)+'px,0,0)'
            },
        });
        }
        var zonaswip = document.querySelector('#sec_productos #zonaswip');
        
        zonaswip.setAttribute('style', 'width:'+(arrlist.length*vw)+'px');

    }

    handleResize(){
        
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        
        // if(vw < 950){

        //     if(this.typeview){
        //         this.typeview = false;
        //         this.nidcurrent = 0;
        //     }
        //     this.ordenTbs();
        // }else if(vw >= 950){

        //     var zonaswip = document.querySelector('#sec_productos #zonaswip');
        //     zonaswip.removeAttribute('style');
        //     this.typeview = true;
        // }

        if(this.bgmodal){
            this.bgmodal.width = vw;
            this.bgmodal.height = vh;

           this.bgwhite();
        }
    }

    bgwhite(){
        const vw = window.innerWidth;
        const vh = window.innerHeight;

        var continfo = document.querySelector('.modal_prod .continfo');

        var elemRect = continfo.getBoundingClientRect();

        var sizebg = 2000;

        if(dvtp != 0){
            sizebg = 600;
        }

        if(this.bggrplo){


            const wbg = elemRect.width;

            this.bggrplo.clear();
            

            this.bggrplo.beginFill(0xffffff);
            this.bggrplo.moveTo(-250, 0);
            this.bggrplo.lineTo(0, (vh/3));
            this.bggrplo.lineTo(0, (vh-100));
            this.bggrplo.lineTo(100, vh);
            this.bggrplo.lineTo(wbg, vh);
            this.bggrplo.lineTo(wbg, 0);
            this.bggrplo.closePath();
            this.bggrplo.endFill();

            this.contbggrplo.position.x = elemRect.left;

            // this.montanacont.position.x = (vw-wbg)/2;
            // this.montanacont.position.y = vh;

            //
            if(vw < 950){

                if(this.contbote){
                    this.contbote.position.x = (vw/2);
                    this.contbote.position.y = vh/3;
                }

                
                if(this.bgnew){
                    var realh = ( (vh*.8)/1 )+30;

                    var porceH = (realh*1)/sizebg;

                    var sobra = vw;

                    if(realh < sobra)
                        porceH = (sobra*1)/sizebg;
                    
                    this.bgnew.scale.set(porceH);
                    this.bgnew.position.x = ((sobra)/2);
                    this.bgnew.position.y = realh/2;
                }

                this.contbggrplo.alpha = 0;

            }else{
                this.contbggrplo.alpha = 1;

                if(this.contbote){
                    this.contbote.position.x = (vw-wbg)/2;
                    this.contbote.position.y = (vh)/2;
                }

                if(this.bgnew){

                    var porceH = (vh*1)/sizebg;

                    var sobra = (vw-wbg)+200;

                    if(vh < sobra)
                        porceH = (sobra*1)/sizebg;
                    
                    this.bgnew.scale.set(porceH);
                    this.bgnew.position.x = ((sobra)/2)-100;
                    this.bgnew.position.y = vh/2;
                }
            }

            this.anchorbote();

            // if(dvtp == 0){

            //     if(vw < 1150){
            //         if(vw < 600)
            //             this.contbotefix.scale.set(.4);
            //         else if(vw < 800)
            //             this.contbotefix.scale.set(.6);
            //         else
            //             this.contbotefix.scale.set(.8);
            //     }else{
    
            //         if(vh < 600)
            //             this.contbotefix.scale.set(.5);
            //         else if(vh < 700)
            //             this.contbotefix.scale.set(.7);
            //         else
            //             this.contbotefix.scale.set(.81);
    
            //     }
            // }
            
        }
    }

    anchorbote(){
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        if(this.animbotella){
            if(vw < 950){
                this.animbotella.anchor.set(0.5, 0.34);
            }else{
                this.animbotella.anchor.set(0.55, 0.48);

            }
        }

        if(this.animcaja){
            if(vw < 950){
                this.animcaja.anchor.set(0.5, 0.34);
                
                this.animcaja.position.x = 0;

            }else{
                this.animcaja.anchor.set(0.55, 0.48);

                this.animcaja.position.x = 50;
            }

        }

        if(this.animbotella)
            this.animbotella.position.x = this.getxbote();
        
    }

    getxbote(){
        const vw = window.innerWidth;

        var xxx = 0;

        if(this.animcaja){
            if(vw < 950){
                xxx = -150;

            }else{
                xxx = -100;
            }
        }


        if(dvtp != 0){
            xxx = -70;

            if(this.animcaja == null){
                xxx = 0;
            }
        }

        return xxx;
    }

    goOneProd(id, idproduct, _color){
        
        this.yeye = getProducts();

        var _this = this;

        this.idselecto = Number(idproduct);

        var planetb = this.htmlToGl.getPlaneTB(id); 

        var tbpos = planetb.position;
        var comoes = false;

        if(this.bgmodal == null){
            this.modalfix = new PIXI.Container();

            this.bgmodal = new PIXI.Graphics();
            this.bgmodal.beginFill(planetb._color);
            this.bgmodal.drawRect(0, 0, 100, 100);
            this.modalfix.position.x = tbpos.x;
            this.modalfix.position.y = tbpos.y;
            
            this.bgmodal.position.x = tbpos.x;
            this.bgmodal.position.y = tbpos.y;
            this.bgmodal.width = planetb.bggr.width;
            this.bgmodal.height = planetb.bggr.height;

            this.htmlToGl.modal.addChild(this.bgmodal);
            comoes = true;
        }
       // this.htmlToGl.modal.addChild(this.modalfix);

        const vw = window.innerWidth;
        const vh = window.innerHeight;

        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var modalprod = document.querySelector('.modal_prod');
        var fixmodalprod = document.querySelector('.fixmodalprod');
        var continfo =  modalprod.querySelector('.continfo');
        var closemodal =  document.querySelector('.modal_prod .closemodal');
        var btncomprar =  document.querySelector('.modal_prod .btncomprar');
        var btncomprarregion =  document.querySelector('.modal_prod .btncomprarregion');
        var btnsnp = document.querySelector('.fixmodalprod .btnsnp');
        
        var arrlabels =  document.querySelectorAll('.modal_prod .continfo .fixinfo .label');

        for (let i = 0; i < arrlabels.length; i++) {
            const element = arrlabels[i];
            element.style.color = '#'+_color;
        }

        var btnsnpright =  btnsnp.querySelector('.right');
        var btnsnprightline =  btnsnpright.querySelector('.fixlinea .linea');
        btnsnpright.style.color = '#'+_color;
        btnsnprightline.style.background = '#'+_color;


        btncomprarregion.style.background = '#'+_color;
        btncomprar.style.background = '#'+_color;

        btncomprar.setAttribute('href', this.yeye[idproduct].link);

        btncomprarregion.setAttribute('href', 'https://bit.ly/3yfJBMr');
        
        var continfocontinfo =  document.querySelector('.modal_prod .continfo');
        var contimgmovil =  document.querySelector('.modal_prod .contimgmovil');
        
        contimgmovil.innerHTML = '';

        var continfofix =  document.querySelector('.modal_prod .continfofix');
        var info_title =  continfofix.querySelector('.title');
        var info_desc =  continfofix.querySelector('.desc');
        var infoguarda =  continfofix.querySelector('.infoguarda');
        var infotipo =  continfofix.querySelector('.infotipo');
        var infonotas =  continfofix.querySelector('.infonotas');
        var infomarinaje =  continfofix.querySelector('.infomarinaje');
        var temperatura =  continfofix.querySelector('.descbot');

        info_title.innerHTML = this.yeye[idproduct].title+" <spam class='grados'>"+this.yeye[idproduct].grado+"º GL</spam>";
        info_desc.innerHTML = this.yeye[idproduct].desc;
        infoguarda.innerHTML = this.yeye[idproduct].guarda;
        infotipo.innerHTML = this.yeye[idproduct].tipo;
        infonotas.innerHTML = this.yeye[idproduct].notas;
        infomarinaje.innerHTML = this.yeye[idproduct].marinaje;
        temperatura.innerHTML = this.yeye[idproduct].temperatura;

        if(this.contbggrplo){
            this.htmlToGl.modal.removeChild(this.contbggrplo);
        }
        
        this.contbggrplo = new PIXI.Container();
        this.bggrplo = new PIXI.Graphics();
        
        if(comoes){
            this.bggrplo.alpha = 0;
            this.bggrplo.position.x = 300;
            
            btnsnp.style.opacity = 0;
        }
        
        this.contbggrplo.addChild(this.bggrplo);
        
        
        var arrimgcaja = ['', 'caja_reservado', 'caja_transparente', '', 'caja_negra', 'caja_enve', 'caja_cumbre'];

        var strxtramovi = '';

        if(dvtp != 0){
            strxtramovi = 'movil/';
        }

        var arrimgborte = [
            'especial',
            'reservado',
            'transparente',
            'roble',
            'negra',
            'envejecido',
            'cumbre',
            'berries'
        ];
        
        var urlbote = getUrlAssets()+'/img/bgprod/';

        if(dvtp != 0){
            urlbote = getUrlAssets()+'/img/bgpro/';
        }

        urlbote += arrimgborte[idproduct]+'.jpg';//
        const labotetexture = PIXI.Texture.from(urlbote);

        if(this.bgnew){
            this.htmlToGl.modal.removeChild(this.bgnew);
        }

        this.bgnew = new PIXI.Sprite( labotetexture );
        this.bgnew.alpha = 0;
        this.bgnew.anchor.set(.5);
        this.htmlToGl.modal.addChild(this.bgnew);

        const loader = new PIXI.Loader();
        loader.add(urlbote);
        
        loader.load((loader, resources) => {
            
        gsap.to(this.bgnew, {
            // duration: 1,
            delay: .3, alpha: 1});
        });

        this.anchorbote();

        //
        this.htmlToGl.modal.addChild(this.contbggrplo);


        var cninfo = continfo.childNodes;

        if(comoes){

            for (let i = 0; i < cninfo.length; i++) {
                const element = cninfo[i];
                
                gsap.set(element, {
                    css: {
                        transform:'translate3D(100px,0,0)',
                        opacity: 0
                    },
                });
            }
        }


        fixmodalprod.classList.remove('oculto');
        document.body.classList.add('example');

        this.bgwhite();


        gsap.to(contsite, {duration: .4, css:{opacity:0} });

        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,-100%,0)'} });
        
        gsap.to(contlogotop, {duration: .4, delay: .1, css:{opacity:0} });

        if(comoes){
            gsap.set(closemodal, {css:{opacity:0} });
            gsap.to(closemodal, {delay: .5, duration: .8, css:{opacity:1} });
            gsap.to(btnsnp, {delay: .5, duration: .8, css:{opacity:1} });

        }
        // gsap.to(this.montana, {
        //     // duration: 1,
        //     delay: .3, alpha: 1});
        // gsap.to(this.montana.position, {
        //     // duration: 1,
        //     delay: .3, y: 0});
        
        if(comoes){

            gsap.to(this.bggrplo, {
                // duration: 1,
                delay: .3,
                alpha: 1});
            gsap.to(this.bggrplo.position, {
                    // duration: 1,
                    delay: .3,
                x: 0});
        

            for (let i = 0; i < cninfo.length; i++) {
                const element = cninfo[i];
                
                var ndela = .4+(.04*i);

                gsap.to(element, {
                    delay: ndela,
                    css: {
                        transform:'translate3D(0,0,0)',
                        opacity: 1
                    },
                });
            }
        }else{

            var continfofix =  modalprod.querySelector('.continfo .continfofix');
            var cnfofix = continfofix.childNodes;

            for (let i = 0; i < cnfofix.length; i++) {
                const element = cnfofix[i];

                var ndela = .4+(.04*i);

                gsap.to(element, {
                    delay: ndela,
                    css: {
                        opacity: 1
                    },
                });
            }
        }

        gsap.to(this.bgmodal, {
                // duration: 1,
                width: vw,
                height: vh,
                ease: 'power.inOut',
            });

        gsap.to(this.bgmodal.position, {
                // duration: 1,
                x: 0,
                y: 0,
                ease: 'power.inOut',
                onComplete: function(){
                    document.querySelector('.modal_prod').classList.add('menuscrolla');
                }
            });

        gsap.to( document.querySelector('.modal_prod') , { duration: .5, css: {  transform:'translate3D(0,0,0)' } });

        setTimeout(function(){
            
        }.bind(this), 1000);
        
        var btnsclose = new BtnTropical(function(){
            return _this.bloqueobtns;
        }.bind(this),{
            click:function(e){
                // planetb.addChild(planetb.fixtb);
                _this.byebyeModal();
            },
            over:function(e){
                
            },
            out:function(e){
                
            }
        });

        btnsclose.addBtn('.modal_prod .closemodal');
    }

    byebyeModal(){
        var contsite = document.querySelector('.contsite');
        var contmenu = document.querySelector('.menu');
        var contlogotop = document.querySelector('.logoTop');
        var fixmodalprod = document.querySelector('.fixmodalprod');
        var modalprod = document.querySelector('.modal_prod');
        
        var continfo =  modalprod.querySelector('.modal_prod .continfo');
        var closemodal =  document.querySelector('.modal_prod .closemodal');
        var btnsnp = document.querySelector('.fixmodalprod .btnsnp');

        var cninfo = continfo.childNodes;

        const vw = window.innerWidth;
        const vh = window.innerHeight;

        gsap.to(btnsnp, {duration: .4, css:{opacity:0} });
        gsap.to(closemodal, {duration: .4, css:{opacity:0} });

        for (let i = 0; i < cninfo.length; i++) {
            const element = cninfo[i];
            
            var ndela = (.02*i);

            var _paramt = {
                delay: ndela,
                duration: .5,
                css: {
                    transform:'translate3D(200px,0,0)',
                    opacity: 0
                },
            };

            if(i >= (cninfo.length-1)){
                _paramt.onComplete = function(){

                    this.htmlToGl.modal.removeChild(this.contbggrplo);
                    this.htmlToGl.modal.removeChild(this.bgmodal);
                    this.htmlToGl.modal.removeChild(this.bgnew);
                    // this.htmlToGl.modal.removeChild(this.contbote);
                    // this.htmlToGl.modal.removeChild(this.montanacont);
                    this.bgmodal = null;
                    this.bggrplo = null;
                    this.bgnew = null;

                    fixmodalprod.classList.add('oculto');
                }.bind(this);
            }
            gsap.to(element, _paramt);
        }

        document.querySelector('.modal_prod').classList.remove('menuscrolla');
        
        document.body.classList.remove('example');
        window.scrollTo(0, this.htmlToGl.scrollmov.target);


        // gsap.to(this.contbotefix, {
        //     duration: .4,
        //     delay: .2, alpha: 0});

        // gsap.to(this.contbotefix.position, {
        //     duration: .4,
        //     delay: .18, x: -500});

        // gsap.to(this.montana, {
        //     // duration: 1,
        //     delay: .14, alpha: 0});

        // gsap.to(this.montana.position, {
        //     // duration: 1,
        //     delay: .14, y: 200});

        gsap.to(this.bgnew, {
            // duration: .4,
            delay: .14, alpha: 0});

        gsap.to(this.bgmodal, {
            duration: 1,
            delay: .2,
            alpha: 0});

        gsap.to(this.bggrplo.position, {
            duration: 1,
            delay: .2,
            x: (vw)});

        //this.htmlToGl.modal.removeChild(this.modalfix);
        gsap.to(contsite, { delay: .3, css:{opacity:1} });
        gsap.to(contmenu, {duration: .4, delay: .3, css:{transform:'translate3d(0,0,0)'} });
       
        gsap.to(contlogotop, {duration: .4, delay: .6, css:{opacity:1}, onComplete:function(){
            
            

        }.bind(this) });

        
    }
}

export default Productos;