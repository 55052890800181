import { getUrlAssets } from '../utils/Utils';
import GlObject from './GlObject';

class PlaneBgSesiones extends GlObject { 
  init(index, el, fxscene, getPosScroll) {
    super.init(index, el, fxscene, getPosScroll);

    this.uIndex = index;

    var fixscro = this.getPosScroll();
    this.lastTopShift = fixscro.speed; 

    var urlcover = getUrlAssets()+'/img/bgandes.png';//
    const texture = PIXI.Texture.from(urlcover);
    
    this.mesh = new PIXI.Sprite( texture );
    this.mesh.alpha = .6;
    this.mesh.anchor.y = 1;
    this.addChild(this.mesh);


    this.imgdata= {w: 300, h: 300};

    this.bggr = new PIXI.Graphics();

    // var tbtype = el.getAttribute('data-tb-color');
    
    this.bggr.beginFill(0x000000);

    this.bggr.moveTo(0, 0);
    this.bggr.lineTo(0, 120);
    this.bggr.lineTo(100, 85);
    this.bggr.lineTo(100, 25);
    this.bggr.lineTo(50, 25);

    var col0 = 0x090909;

    this.bggr.beginFill(col0);

    this.bggr.moveTo(0, 0);
    this.bggr.lineTo(0, 120);
    this.bggr.lineTo(30, 100);


    this.bggr.beginFill(0x101010);

    this.bggr.moveTo(0, 0);
    this.bggr.lineTo(30, 100);
    this.bggr.lineTo(50, 70);


    this.bggr.beginFill(col0);

    this.bggr.moveTo(0, 0);
    this.bggr.lineTo(50, 70);
    this.bggr.lineTo(100, 25);
    this.bggr.lineTo(50, 25);
    
    this.bggr.endFill();

    this.addChild(this.bggr);

    this.lineas = [];

    var s = this.lineWidth = .1;
    var c = this.lineColor = "0x272727";

    this.lineascont = new PIXI.Container();

    var arraylines = [{type:'x', v0:30, v1:80}, {type:'x', v0:90, v1:40}, {type:'y', v0:70, v1:30}];
    for (let i = 0; i < arraylines.length; i++) {
      this.lineas[i] = new PIXI.Graphics();
      
      this.lineas[i].lineStyle(s, c);


      var initpos = this.getposli(0, arraylines[i].v0, arraylines[i].type);

      this.lineas[i].moveTo(initpos.x, initpos.y);

      var finpos = this.getposli(100, arraylines[i].v1, arraylines[i].type);
      this.lineas[i].lineTo(finpos.x, finpos.y);
      //this.lineas[i].stroke();
      this.lineascont.addChild(this.lineas[i]);
    }

    this.maskalineas = this.bggr.clone();

    this.addChild(this.lineascont);
    this.addChild(this.maskalineas);
    this.lineascont.mask = this.maskalineas;
  }

  getposli(_posother, _pos, _type){

    var liposx = _posother;
    var liposy = _posother;

    if(_type == 'x'){
      liposx = _pos;
    }else{
      liposy = _pos;
    }

    return {x: liposx, y:liposy};
  }

  goIntro(_delay, _callback){
    //console.log('intro tb');
    // this.setNoiseParam();
    // this.material.uniforms.uActive.value = 1;
    // //this.material.uniforms.uProg = 0.25;
    // gsap.set(this.material.uniforms.uProg, { value: 0.5 });


    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: 0,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 0,
    //   });
  }


  goBye(_delay, _callback){
    // this.material.uniforms.uActive.value = 1;
  
    if(this.interimg)
      clearInterval(this.interimg);

    gsap.to(this, {
      delay: _delay,
      duration: .25,
      alpha: 0,
      ease: 'power.inOut',
      onComplete:function(){

        // if(this && this.material){
        //     this.material.uniforms.uActive.value = 0;
        // }

        if(_callback)
          _callback();
      }.bind(this),
    });
    // gsap.to(this.material.uniforms.uProg, {
    //   delay: _delay,
    //   duration: 2.5,
    //   value: -1,
    //   ease: 'power.inOut',
    //   onComplete:function(){

    //     if(this && this.material){
    //         this.material.uniforms.uActive.value = 0;
    //     }

    //     if(_callback)
    //       _callback();
    //   }.bind(this),
    // });

    // gsap.to(this.material.uniforms.mixOpacity, {
    //     delay: _delay,
    //     duration: 2,
    //     value: 1,
    //   });
  }

  resize(){
    super.resize();

    this.resizeFixTexture();
  }

  resizeFixTexture(){
    if(this.bggr){
      var hh = this.bounds.height;
      var ww = this.bounds.width;

      var hhb = this.bounds.height-30;
      var wwb = this.bounds.width-30;

      var vW = 1;
      var vH = 1;
      var vWb = 1;
      var vHb = 1;

      if(ww > hh){
        vH = ( (hh*1)/ww );
        vHb = ( (hhb*1)/(wwb) );
      }else{
        vW = ( (ww*1)/hh );
        vWb = ( (wwb*1)/(hhb) );
      }

      // this.material.uniforms.u_adjust_uv.value = new THREE.Vector2(vW, vH);
      // this.material.uniforms.u_adjust_uv_border.value = new THREE.Vector2(vWb, vHb);


      this.bggr.width = ww;
      this.bggr.height = hh;

      this.lineascont.width = ww;
      this.lineascont.height = hh;

      this.maskalineas.width = ww;
      this.maskalineas.height = hh;


      var porceW = (ww*1)/1;

      var porceH = (hh*.5)/1;

      //this.mesh.scale.set(1, porceH);
      this.mesh.position.y = porceH;
      this.mesh.position.x = porceW;
      this.mesh.scale.y = -1;
      this.mesh.scale.x = -1;
      
      // this.mesh.width = ww-40;
      // this.mesh.height = ww-40;

      // this.mesh.position.x = 20;
      // this.mesh.position.y = 20;

    }
  }

  byebye(){
    this.removeChild(this.bggr);
    this.removeChild(this.mesh);

    // this.material.dispose();
    // this.geometry.dispose();
    // this.texture.dispose();

    this.material = undefined;
    this.geometry = undefined;
    this.texture = undefined;
    this.mesh = null;
  }

}

export default PlaneBgSesiones;